<template>
  <div>
    <v-card class="mt-8">
      <v-card-title class="justify-space-between d-flex py-0 pl-0">
        <v-col cols="6">
          <p class="text-overline">Summary data report</p>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-toolbar class="text-center mb-8">
          <DateRangePicker
            v-model:start-date="startDate"
            v-model:end-date="endDate"
            :max-day-range="maxDays"
            width="400px"
            class="ma-auto"
            @valid="datesAreValid = $event"
          />
        </v-toolbar>

        <v-alert v-if="summaryDataError" tile type="error" class="mt-2 mb-4">
          {{ summaryDataError.userMessage }}
        </v-alert>
        <v-alert v-if="!isSensitiveDataVisible" tile type="info" class="mt-2 mb-4">
          {{ 'Sensitive data not enabled, most columns will be hidden.' }}
        </v-alert>
        <v-alert v-if="datesError" tile type="error" class="mt-2 mb-4">
          {{ datesError }}
        </v-alert>

        <v-data-table
          item-value="day"
          :items="summaryData"
          :items-per-page="100"
          :headers="headers"
          :loading="waitingForData"
          :no-data-text="waitingForData ? 'Loading data...' : 'No data found'"
          :sort-by="[{ key: 'day', order: 'desc' }]"
          :expand-on-click="true"
          :show-expand="true"
          :row-props="
            ({ item }) => {
              return { style: `cursor: ${item.isExpandable ? 'pointer' : 'default'}` }
            }
          "
        >
          <template #[`item.day`]="{ item }">
            <span class="text-no-wrap">{{ item.date }}</span>
          </template>
          <template #[`item.selfTestSuccess`]="{ item }">
            <v-icon v-if="item.selfTestSuccess === true" color="green">mdi-check-circle</v-icon>
            <v-icon v-else-if="item.selfTestSuccess === false" color="red">mdi-close-circle</v-icon>
          </template>
          <template #[`item.fieldTestSuccess`]="{ item }">
            <v-icon v-if="item.fieldTestSuccess === true" color="green">mdi-check-circle</v-icon>
            <v-icon v-else-if="item.fieldTestSuccess === false" color="red">mdi-close-circle</v-icon>
          </template>
          <template #[`item.events`]="{ item }">
            <p v-if="item.events?.length == 1">{{ item.events[0] }}</p>
            <p v-else-if="item.events?.length > 1">
              {{ item.events?.length }} Events
              <v-icon>mdi-chevron-down</v-icon>
            </p>
          </template>
          <template #expanded-row="{ item, columns }">
            <template v-if="item.isExpandable">
              <tr>
                <td v-if="item.selfTestDetails" :colspan="columns.length">
                  <div class="pa-4">
                    <b>Self test errors ({{ item.selfTestDetails.length }})</b>
                    <ul v-for="testError in item.selfTestDetails" :key="testError">
                      <li class="ml-8">{{ testError }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td v-if="item.fieldTestDetails" :colspan="columns.length">
                  <div class="pa-4">
                    <b>Field test errors ({{ item.fieldTestDetails.length }})</b>
                    <ul v-for="testError in item.fieldTestDetails" :key="testError">
                      <li class="ml-8">{{ testError }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td v-if="item.events" :colspan="columns.length">
                  <div class="pa-4">
                    <b>Debug events ({{ item.events.length }})</b>
                    <ul v-for="event in item.events" :key="event">
                      <li class="ml-8">{{ event }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <template #item.data-table-expand="{ isExpanded, item, internalItem }">
            <template v-if="item.isExpandable">
              <v-btn v-if="!isExpanded(internalItem)" icon="mdi-chevron-down" />
              <v-btn v-if="isExpanded(internalItem)" icon="mdi-chevron-up" />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { nonSensitiveSummaryHeaders, summaryHeaders } from '#views/members/constants'

  import { summaryDataMaxDays } from '#views/members/account/constants'

  import { getDaysBetween } from '#utils/datetime/calculate'
  import { extractSummaryDataFromResponse } from '#utils/summary-data/extract'

  import { AppStore, SummaryStore, UserStore } from '#stores'

  import { DisplayTimezone, Nullable, SummaryDataReportItem, SummaryDataResponse } from '#types'

  @Component
  class SummaryReport extends mixins(DateTime) {
    @Prop() public userUuid!: string

    public startDate = dayjs().subtract(14, 'day').format('YYYY-MM-DD')
    public endDate = dayjs().format('YYYY-MM-DD')
    public maxDays = summaryDataMaxDays
    public datesError: Nullable<string> = null
    public chosenDays = 0

    public datesAreValid = false
    public summaryDataReportItems: SummaryDataReportItem[] = []

    protected readonly appStore = new AppStore()
    protected readonly userStore = new UserStore()
    protected readonly summaryStore = new SummaryStore()

    public get isSensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get headers() {
      return this.isSensitiveDataVisible ? summaryHeaders : nonSensitiveSummaryHeaders
    }

    public get waitingForData(): boolean {
      return this.summaryStore.waitingForSummaryData()
    }

    public get summaryDataError() {
      return this.summaryStore.summaryDataError
    }

    public get summaryData() {
      return this.summaryDataReportItems
    }

    public get summaryDataResponse() {
      return this.summaryStore.summaryDataResponse
    }

    public get displayTimezone() {
      return this.prefsStore.displayTimezone
    }

    @Watch('summaryDataResponse')
    protected onSummaryDataResponseChanged(summaryDataResponse: Nullable<SummaryDataResponse>) {
      if (summaryDataResponse) {
        this.summaryDataReportItems = extractSummaryDataFromResponse(
          summaryDataResponse,
          this.prefsStore.displayTimezone,
        )
      }
    }

    @Watch('displayTimezone')
    protected onTimezoneSettingChanged(displayTimezone: DisplayTimezone) {
      if (this.summaryDataResponse) {
        this.summaryDataReportItems = extractSummaryDataFromResponse(this.summaryDataResponse, displayTimezone)
      }
    }

    @Watch('summaryDataReportItems')
    protected onSummaryDataChanged(newSummaryData: SummaryDataReportItem[]) {
      if (newSummaryData.length) {
        logEvent(this.$analytics, 'troubleshooter_available', {
          category: `User:Timeline:Dashboard Summary data V2 available`,
          action: 'Filters in Timeline:Dashboard SummaryV2 available',
          label: 'Filters in Timeline:Dashboard SummaryV2 available',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
      }
    }

    @Watch('userUuid', { immediate: true })
    protected onUuidChanged() {
      this.updateData()
    }

    @Watch('startDate')
    @Watch('endDate')
    @Watch('datesAreValid', { immediate: true })
    protected onDatesChanged() {
      this.chosenDays = getDaysBetween(new Date(this.startDate), new Date(this.endDate))
      if (this.chosenDays > this.maxDays) {
        this.datesError = `You attempted to fetch too much data (${this.chosenDays} days worth). Maximum is ${this.maxDays} days. Please change the start or end date.`
      } else {
        this.datesError = null
      }
      if (this.datesAreValid) {
        this.updateData()
      }
    }

    @Debounce(1000)
    private updateData() {
      if (this.userUuid) {
        this.summaryStore.getSummaryData({
          userUuid: this.userUuid,
          from: this.startDate,
          to: this.endDate,
        })
      }
    }
  }

  export default toNative(SummaryReport)
</script>
