<template>
  <div v-if="subscription">
    <div class="d-flex mb-2">
      <div class="d-flex flex-column flex-grow-1">
        <h5 class="subtitle-1 font-weight-bold mb-2">{{ title }}</h5>

        <v-row class="d-flex flex-row">
          <v-col class="d-flex flex-row" cols="4">
            <v-text-field v-model="planName" label="Plan type" readonly />
          </v-col>
          <v-spacer />
          <v-col class="d-flex flex-row" cols="8">
            <v-text-field v-model="startDate" label="Start date" readonly style="flex-basis: 250px" />
            <div v-if="endDate" class="pa-5">-</div>
            <v-text-field v-if="endDate" v-model="endDate" label="End date" readonly style="flex-basis: 250px" />
            <v-btn
              v-else-if="!endDate && editSubscription"
              class="flex-grow-1 mt-3"
              style="flex-basis: 250px"
              @click="endDateDialogOpen = true"
            >
              Set End Date
            </v-btn>
          </v-col>
        </v-row>

        <v-list class="pa-0">
          <!-- options have no uids and added_at+removed_at can be same for several items -->
          <v-row v-for="(option, index) in shownOptions" :key="index" class="d-flex flex-row ma-0">
            <v-col cols="1" class="right-bar-subscription" />
            <v-col class="d-flex flex-row" cols="4">
              <v-text-field
                :model-value="option.data.participant_seat_count || 'Unlimited'"
                label="Seat Count"
                placeholder="Unlimited"
                style="max-width: 100px"
                readonly
                class="mr-6"
              />

              <v-text-field
                v-if="org.type !== 'health_private' && org.type !== 'illness_detection'"
                :model-value="option.data.blackout_mode ? 'Enabled' : 'Disabled'"
                label="Remote App Management"
                placeholder="Disabled"
                readonly
              />
            </v-col>

            <v-col class="d-flex flex-row" cols="7">
              <v-text-field
                :model-value="formatDateTime(option.added_at)"
                label="Valid from"
                readonly
                style="flex-basis: 250px"
              />
              <div class="pa-5">-</div>
              <v-text-field
                :model-value="option.removed_at ? formatDateTime(option.removed_at) : 'Until subscription ends'"
                label="Valid to"
                style="flex-basis: 250px"
                readonly
              />
              <v-btn
                v-if="isCurrentOrFuture && editSubscription && index === shownOptions.length - 1"
                icon
                variant="plain"
                class="float-right"
                style="flex-basis: 50px"
                @click="editSubscriptionClick()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list>
      </div>
      <div>
        <v-btn
          v-if="isCurrentOrFuture && editSubscription"
          variant="plain"
          icon
          class="ma-3 mr-n3"
          @click="deleteDialogOpen = true"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>

    <v-dialog v-if="deleteDialogOpen" v-model="deleteDialogOpen" width="500">
      <v-card>
        <v-card-title>Delete subscription</v-card-title>

        <v-card-text>
          This will delete the subscription
          <strong>{{ planName }}</strong>
          from
          {{ startDate }}
          {{ endDate ? `to ${endDate}` : 'onwards' }}.

          <p class="mt-4 mb-1 text-red" style="text-transform: uppercase">
            Deleting a subscription is an exceptional action
          </p>
          <p>Under normal circumstances subscriptions should never be deleted.</p>
          <p>
            <strong>You should not do this unless you know the subscription is not in any fiscal records yet.</strong>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="deleteDialogOpen = false">Cancel</v-btn>
          <v-btn variant="text" color="red" @click="confirmDelete()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="endDateDialogOpen" v-model="endDateDialogOpen" width="500">
      <v-card>
        <v-alert v-if="!!endDateError" tile class="px-5 v-sheet--tile" type="error" color="red">
          <div class="px-3">
            {{ endDateError }}
          </div>
        </v-alert>

        <v-card-title>Set end date</v-card-title>

        <v-card-text>
          <div>
            <DatePicker
              v-model="endDateString"
              label="End date"
              :min-date="minDateSubscriptionEnd"
              :max-date="null"
              @valid="endDateValid = $event"
            />
          </div>

          <p>Once an end date is set for a subscription, it can no longer be changed.</p>

          <p v-if="endDateTime">
            <strong class="mr-2">New end date:</strong>
            <span>{{ formatDateTime(endDateTime) }}</span>
          </p>

          <v-checkbox v-model="endDateConfirmation" color="blue" label="I understand this can't be undone" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="endDateDialogOpen = false">Cancel</v-btn>
          <v-btn
            variant="text"
            color="blue"
            :disabled="!endDateConfirmation || !endDateValid || dataWait"
            @click="confirmEndDate()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="optionsDialogOpen" v-model="optionsDialogOpen" width="500">
      <v-card>
        <v-alert v-if="!!seatCountError" tile class="px-5 v-sheet--tile" type="error" color="red">
          <div class="px-3">
            {{ seatCountError }}
          </div>
        </v-alert>
        <v-card-title>Set Seat Count</v-card-title>

        <v-card-text>
          <p>
            <strong class="mr-2">Current Seat Count:</strong>
            <span>
              {{ seatCount || 'unlimited' }}
            </span>
          </p>

          <v-text-field v-model.number="newSeatCount" type="number" label="New seat count" placeholder="(unlimited)" />

          <v-checkbox
            v-if="org.type !== 'health_private' && org.type !== 'illness_detection'"
            v-model="newBlackoutMode"
            label="Enable Remote App Management"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="cancelChanges()">Cancel</v-btn>
          <v-btn
            variant="text"
            color="blue"
            :disabled="dataWait || (newSeatCount === null && newBlackoutMode === null)"
            @click="confirmOptions()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { formatPlanName, responseHandler } from '#views/teams/constants'

  import { TeamsStore } from '#stores'

  import { TeamsOrgSubscription, TeamsOrganization, TeamsPlan } from '#types'

  const DATE_FORMAT_DISPLAY = 'HH:mm - DD MMM YYYY [UTC]'

  @Component
  class Subscription extends Vue {
    @Prop() public org!: TeamsOrganization
    @Prop() public plans!: TeamsPlan[]
    @Prop() public defaultPlan!: any
    @Prop() public subscription!: TeamsOrgSubscription
    @Prop() public editSubscription!: boolean

    @Prop({ default: false }) public condensed!: boolean

    public endDate: string | null = null
    public startDate: string | null = null

    // Setting end date
    public endDateTime = ''
    public endDateError = ''
    public endDateString = ''
    public endDateValid = false
    public endDateDialogOpen = false
    public endDateConfirmation = false

    // Setting seat count
    public seatCountError = ''
    public optionsDialogOpen = false
    public newSeatCount: number | null | undefined = null
    public newBlackoutMode: boolean | null | undefined = null

    // Subscription deletion
    public deleteDialogOpen = false

    public teamsStore = new TeamsStore()

    public get title() {
      const now = new Date().getTime()
      const start = this.subscription?.valid_from ? new Date(this.subscription?.valid_from).getTime() : undefined
      const end = this.subscription.valid_to ? new Date(this.subscription.valid_to).getTime() : undefined

      if (end && end <= now) {
        return 'Past Subscription'
      } else if (now && start && now < start) {
        return 'Future Subscription'
      } else {
        return 'Current Subscription'
      }
    }

    public get dataWait() {
      return this.teamsStore.dataWait
    }

    public get planName() {
      return formatPlanName(this.subscription.plan, this.subscription)
    }

    public get shownOptions() {
      const options = this.subscription.options
      return this.condensed ? (options.length > 0 ? new Array(options[options.length - 1]) : []) : options
    }

    public get seatCount() {
      const options = this.subscription.options
      return options.length > 0 ? options[options.length - 1].data.participant_seat_count : undefined
    }

    public set seatCount(value: number | undefined) {
      this.newSeatCount = value
    }

    public get blackoutMode() {
      const options = this.subscription.options
      return options.length > 0 ? options[options.length - 1].data.blackout_mode : undefined
    }

    public set blackoutMode(value: boolean | undefined) {
      this.newBlackoutMode = value
    }

    public get isCurrentOrFuture() {
      return !this.subscription.valid_to || new Date().getTime() < new Date(this.subscription.valid_to).getTime()
    }

    public get minDateSubscriptionEnd() {
      return this.$dayjs(this.subscription.valid_from).format('YYYY-MM-DD')
    }

    @Watch('subscription', { immediate: true })
    protected onSubscriptionChange(_sub: TeamsOrgSubscription) {
      this.startDate = this.subscription?.valid_from ? this.formatDateTime(this.subscription.valid_from) : null
      this.endDate = this.subscription?.valid_to ? this.formatDateTime(this.subscription?.valid_to) : null
      this.endDateString = this.minDateSubscriptionEnd
    }

    @Watch('endDateString', { immediate: true })
    protected onEndChange(isoString: string) {
      this.endDateTime = `${isoString}${this.subscription?.valid_from?.substring(10)}`
    }

    public confirmEndDate() {
      this.endDateError = ''

      this.teamsStore
        .setSubscriptionEndDate({
          orgID: this.org.uid,
          subscriptionID: this.subscription.subscription_uid,
          endDate: this.endDateTime,
        })
        .then(
          responseHandler({
            onError: (error) => {
              this.endDateError = error
            },
            onSuccess: () => {
              this.endDateDialogOpen = false
            },
          }),
        )
    }

    public cancelChanges() {
      this.seatCountError = ''
      this.newSeatCount = null
      this.newBlackoutMode = null
      this.optionsDialogOpen = false
    }

    public confirmOptions() {
      this.seatCountError = ''

      this.teamsStore
        .setSubscriptionOptions({
          orgID: this.org.uid,
          subscriptionID: this.subscription.subscription_uid,
          seatCount: this.newSeatCount,
          blackoutMode: this.newBlackoutMode,
        })
        .then(
          responseHandler({
            onError: (error) => {
              this.seatCountError = error
            },
            onSuccess: () => {
              this.optionsDialogOpen = false
              this.newSeatCount = null
              this.newBlackoutMode = null
            },
          }),
        )
    }

    public editSubscriptionClick() {
      this.optionsDialogOpen = true

      this.newSeatCount = this.seatCount
      this.newBlackoutMode = this.blackoutMode
    }

    public confirmDelete() {
      this.teamsStore
        .deleteSubscription({
          orgID: this.org.uid,
          subscriptionID: this.subscription.subscription_uid,
        })
        .then(() => {
          this.deleteDialogOpen = false
        })
    }

    public formatDateTime(date: string): string {
      return this.$dayjs(date).utc().format(DATE_FORMAT_DISPLAY)
    }
  }

  export default toNative(Subscription)
</script>

<style lang="scss">
  .right-bar-subscription {
    border-right: 2px solid grey;
  }
</style>
