<template>
  <div v-if="warrantyReturnInfo" class="pb-5">
    <template v-if="warrantyReturnInfo.replacementFor || warrantyReturnInfo.replacedBy">
      <v-divider />

      <v-row>
        <v-col cols="12" md="6">
          <template v-if="warrantyReturnInfo.replacementFor">
            <v-list-subheader class="mb-n2">Replacement for:</v-list-subheader>

            <v-list dense>
              <v-list-item>
                <v-row class="align-center">
                  <v-col cols="6">RA transaction ID:</v-col>
                  <v-col cols="4">
                    {{ warrantyReturnInfo.replacementFor.returnAuthorizationTransactionId || '-' }}

                    <v-tooltip location="top">
                      <template
                        v-if="warrantyReturnInfo.replacementFor.returnAuthorizationTransactionId"
                        #activator="{ props }"
                      >
                        <v-btn
                          v-bind="props"
                          variant="plain"
                          color="grey"
                          icon="mdi-content-copy"
                          @click.stop="
                            useClipboard.copy(warrantyReturnInfo.replacementFor.returnAuthorizationTransactionId)
                          "
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy RA transaction ID to clipboard' }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item>
                <v-row class="align-center">
                  <v-col cols="6">{{ warrantyReturnInfo.replacementFor.serialNumberTitle }}:</v-col>
                  <v-col cols="6">
                    <div
                      v-if="
                        warrantyReturnInfo.replacementFor.serialNumber &&
                        warrantyReturnInfo.replacementFor.serialNumberParsed
                      "
                    >
                      <router-link
                        :to="{
                          name: 'ring-details',
                          params: { ringOrChargerSerial: warrantyReturnInfo.replacementFor.serialNumberParsed },
                        }"
                        :target="openLinkTarget"
                      >
                        {{ warrantyReturnInfo.replacementFor.serialNumber }}
                      </router-link>

                      <v-tooltip location="top">
                        <template v-if="warrantyReturnInfo.replacementFor.serialNumber" #activator="{ props }">
                          <v-btn
                            v-bind="props"
                            icon="mdi-content-copy"
                            color="grey"
                            variant="plain"
                            @click="useClipboard.copy(warrantyReturnInfo.replacementFor.serialNumber)"
                          />
                        </template>
                        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </template>

          <template v-else>
            <v-list-subheader>Not a replacement</v-list-subheader>
          </template>
        </v-col>

        <v-col cols="12" md="6">
          <template v-if="warrantyReturnInfo.replacedBy">
            <v-list-subheader class="mb-n2">Replaced by:</v-list-subheader>

            <v-list dense>
              <v-list-item>
                <v-row class="align-center">
                  <v-col cols="6">Order transaction ID:</v-col>
                  <v-col cols="6">
                    {{ warrantyReturnInfo.replacedBy.orderTransactionId || '-' }}

                    <v-tooltip location="top">
                      <template v-if="warrantyReturnInfo.replacedBy.orderTransactionId" #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          icon="mdi-content-copy"
                          color="grey"
                          variant="plain"
                          @click="useClipboard.copy(warrantyReturnInfo.replacedBy.orderTransactionId)"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy order transaction ID to clipboard' }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item>

              <v-list-item>
                <v-row class="align-center">
                  <v-col cols="6">{{ warrantyReturnInfo.replacedBy.serialNumberTitle }}:</v-col>
                  <v-col cols="6">
                    <router-link
                      v-if="warrantyReturnInfo.replacedBy.serialNumberParsed"
                      :to="{
                        name: 'ring-details',
                        params: { ringOrChargerSerial: warrantyReturnInfo.replacedBy.serialNumberParsed },
                      }"
                      :target="openLinkTarget"
                    >
                      {{ warrantyReturnInfo.replacedBy.serialNumber }}
                    </router-link>

                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          icon="mdi-content-copy"
                          color="grey"
                          variant="plain"
                          @click="useClipboard.copy(warrantyReturnInfo.replacedBy.serialNumber)"
                        />
                      </template>
                      {{ useClipboard.copied ? 'Copied' : 'Copy serial number to clipboard' }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </template>

          <template v-else>
            <v-list-subheader>Not replaced</v-list-subheader>
          </template>
        </v-col>
      </v-row>
    </template>

    <template v-if="warrantyReturnInfo.returnAuthorization">
      <v-divider />

      <v-row>
        <v-col cols="12" md="6">
          <v-list-subheader class="mb-n2">Return Authorization:</v-list-subheader>

          <v-list dense>
            <v-list-item>
              <v-row class="align-center">
                <v-col cols="6">Transaction ID:</v-col>
                <v-col cols="6">
                  {{ warrantyReturnInfo.returnAuthorization.transactionId }}

                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon="mdi-content-copy"
                        color="grey"
                        variant="plain"
                        @click="useClipboard.copy(warrantyReturnInfo.returnAuthorization.transactionId)"
                      />
                    </template>
                    {{ useClipboard.copied ? 'Copied' : 'Copy transaction ID to clipboard' }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col>Waiting to be returned:</v-col>
                <v-col>{{ warrantyReturnInfo.returnAuthorization.waitingToBeReturned || '-' }}</v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col>Reason for replacement:</v-col>
                <v-col class="align-start">
                  {{ warrantyReturnInfo.returnAuthorization.reason || 'Not on record / unknown' }}
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-row>
                <v-col>Status:</v-col>
                <v-col class="align-start">
                  {{ warrantyReturnInfo.returnAuthorization.status || 'Not available' }}
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col>Timestamp:</v-col>
                <v-col class="align-start">
                  {{ formatDateTime(warrantyReturnInfo.returnAuthorization.timestamp, 'DD MMM YYYY HH:MM') }}
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-row>
                <v-col>Last modified:</v-col>
                <v-col class="align-start">
                  {{ formatDateTime(warrantyReturnInfo.returnAuthorization.lastModifiedAt, 'DD MMM YYYY HH:MM') }}
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </template>

    <template v-if="someItemsHaveBeenReturned">
      <v-divider />

      <v-row>
        <v-col cols="12" md="12">
          <v-list-subheader>Returned Items</v-list-subheader>
          <v-table dense>
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Return #</th>
                <th scope="col">Agent handling</th>
                <th scope="col">Factory reset status</th>
                <th scope="col" style="text-align: right">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in warrantyReturnInfo!.returnAuthorization!.returnedItems" :key="index">
                <td>{{ item.itemType }}</td>
                <td>{{ item.returnNumber }}</td>
                <td>{{ item.handlerEmail }}</td>
                <td>
                  <span v-if="item.factoryResetStatus">{{ item.factoryResetStatus }}</span>
                  <div v-if="item.factoryResetReason">Reason: {{ item.factoryResetReason }}</div>
                </td>
                <td style="text-align: right">{{ formatDateTime(item.returnedAt, 'DD MMM YYYY') }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </template>

    <v-divider />

    <v-row class="mt-2">
      <v-col cols="12" md="12">
        <v-list-subheader>Zendesk Warranty Notes:</v-list-subheader>

        <v-list-item>
          <div>
            {{ warrantyReturnInfo?.warrantyNotes ?? ' No notes available' }}
          </div>
        </v-list-item>
      </v-col>
    </v-row>

    <template v-if="warrantyReturnInfo?.returnAuthorization && warrantyReturnInfo.returnAuthorization.transactionId">
      <v-divider />

      <v-row>
        <v-col cols="12" md="12">
          <v-textarea v-model="editableExtraNotes" rows="2" label="Extra notes related to this warranty return case" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { UserStore } from '#stores'

  import { Nullable, WarrantyReturnInfo } from '#types'

  @Component({})
  class RingReturnDetails extends mixins(DateTime) {
    @Prop() public warrantyReturnInfo!: Nullable<WarrantyReturnInfo>

    @Prop({ default: '_self' }) public openLinkTarget: '_blank' | '_self' = '_self'

    public editableExtraNotes = ''

    public readonly useClipboard = useClipboard()

    protected readonly userStore = new UserStore()

    public get someItemsHaveBeenReturned() {
      return (
        !!this.warrantyReturnInfo?.returnAuthorization?.returnedItems &&
        this.warrantyReturnInfo.returnAuthorization.returnedItems.length > 0
      )
    }

    @Watch('editableExtraNotes')
    protected async warrantyNotesChanged(val: string) {
      await this.updateWarrantyNotes(val)
    }

    @Watch('warrantyReturnInfo')
    protected async warrantyReturnInfoChanged() {
      const netsuiteId = this.warrantyReturnInfo?.returnAuthorization?.transactionId

      if (netsuiteId) {
        this.editableExtraNotes = await this.userStore.setSubscribeAndWarrantyNotes({ netsuiteId: netsuiteId })
      }
    }

    public async created() {
      await this.warrantyReturnInfoChanged()
    }

    @Debounce(200)
    private async updateWarrantyNotes(val: string) {
      this.editableExtraNotes = val

      const netsuiteId = this.warrantyReturnInfo?.returnAuthorization?.transactionId

      if (netsuiteId) {
        await this.userStore.updateWarrantyNotes({
          netsuiteId,
          warrantyNotes: this.editableExtraNotes,
        })
      }
    }
  }

  export default toNative(RingReturnDetails)
</script>
