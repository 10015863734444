<template>
  <v-container v-if="contactDetails">
    <v-row>
      <v-col>
        <div class="text-subtitle-2">
          {{ contactTypeText }}
          <v-tooltip v-if="$featureEnabled('editBillingAddress')" location="bottom">
            <template #activator="{ props }">
              <v-btn
                icon="mdi-pencil"
                variant="plain"
                color="blue"
                size="small"
                class="ml-2"
                v-bind="props"
                @click.stop="updateAddress()"
              />
            </template>
            <span>Edit address</span>
          </v-tooltip>
        </div>
        <div>
          <p>Name: {{ contactDetails.firstName }} {{ contactDetails.lastName }}</p>
          <p>Email: {{ contactDetails.personalEmail }}</p>
          <p v-if="contactDetails.telephone">Phone: {{ contactDetails.telephone }}</p>
          <p>Address1: {{ contactDetails.address1 }}</p>
          <p v-if="contactDetails.address2">Address2: {{ contactDetails.address2 }}</p>
          <p v-if="contactDetails.state">State: {{ contactDetails.state }}</p>
          <p>
            {{ contactDetails.postalCode }} {{ contactDetails.city }},
            {{ contactDetails.country }}
          </p>
        </div>
      </v-col>
    </v-row>
    <ShippingDetails ref="shippingDetailsDialogV2" :member="user" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ShippingDetails } from '#views/members/billing/ShippingDetails.vue'

  import { getHumanReadableAddressType } from '#utils/utils'

  import { ContactType, PaymentMethodsContact, ShippingDetailsDisabledInputs } from '#types'
  import { Member } from '#types'

  @Component({})
  class ContactDetails extends Vue {
    @Prop() public user!: Member
    @Prop() public contactDetails!: PaymentMethodsContact
    @Prop() public contactType!: ContactType

    public declare $refs: {
      shippingDetailsDialogV2: ShippingDetails
    }

    public get contactTypeText(): string {
      return this.contactType === 'billToContact' ? 'Bill to contact' : 'Ship to contact'
    }

    public async updateAddress() {
      const addressType = getHumanReadableAddressType(this.contactType)

      const text = `Updating the ${addressType} address for ${this.user?.email || 'this account'}.`

      const action = this.contactType === 'billToContact' ? 'updateBillingAddress' : 'updateShippingAddress'

      // Country field disabled for shipping address, because SUPA endpoint doesn't allow changing it https://ouraring.slack.com/archives/CTPBXAPJL/p1723032253104619
      const disabledInputs: ShippingDetailsDisabledInputs =
        this.contactType === 'shipToContact'
          ? { country: "Not allowed to change the country of a member's shipping address" }
          : {}

      await this.$refs.shippingDetailsDialogV2.open(
        action,
        `Update ${addressType} address`,
        text,
        null,
        this.contactDetails,
        disabledInputs,
      )
    }
  }

  export default toNative(ContactDetails)
</script>

<style lang="scss" scoped>
  :deep(.v-btn) {
    margin-bottom: -2px;
  }
</style>
