<template>
  <div class="text-no-wrap" @mouseover="hover = true" @mouseleave="hover = false">
    {{ data }}

    <v-tooltip location="top">
      <template #activator="{ props }">
        <v-btn v-bind="props" variant="text" @click.stop="copyToClipboard()">
          <v-icon v-show="hover" size="large">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      {{ message }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class HoverCopy extends Vue {
    @Prop() public data!: any
    @Prop() public message!: string

    public useClipboard = useClipboard()

    public hover = false
    public timeout = 1500

    public copyToClipboard() {
      this.useClipboard.copy(this.data)

      this.$notify('Copied to clipboard', [{ text: 'Close' }])
    }
  }

  export default toNative(HoverCopy)
</script>
