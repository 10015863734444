import { validate as uuidValidate } from 'uuid'

import { emailPattern } from '#views/teams/constants'

export interface UserSearchResponseSingleUser {
  email: string
  registrationDate: string
  ringCount: number
  uuid: string
  flags: any
  deletionTickets?: MemberSearchDeletionTicketResponse[]
  Group?: string
}

export interface UserSearchResponse {
  count: number
  next?: string
  previous?: string
  contents: UserSearchResponseSingleUser[]
  maxResults?: number
}

export interface MemberSearchDeletionTicketResponse {
  aspects: string[]
  createdAt: string
  initiatingAdminEmail: string
  initiatingAdminUid: string
  initiationType: string
  status: string
  uid: string
  updatedAt: string
  userUid: string
}

export enum SearchType {
  SEARCH_TYPE_UUID = 'uuid',
  SEARCH_TYPE_EMAIL_EXACT = 'emailExact',
  SEARCH_TYPE_EMAIL_FUZZY = 'emailFuzzy',
  SEARCH_TYPE_EMAIL_PARTIAL = 'emailPartial',
  SEARCH_TYPE_EMAIL_DELETED_EXACT = 'email',
}

interface SearchCommon {
  // Not exported, meant to be extended by interfaces that resolve type ambiguities
  keyword: string
  errorMessage?: string
}

interface CheckboxConfig {
  enabled: boolean
}

interface SearchCheckboxes {
  fuzzyMatching: CheckboxConfig
  deletedAccountsOnly: CheckboxConfig
}

export interface SearchConfig extends SearchCommon {
  searchType: SearchType
  confirmationMessage?: string
  checkboxes: SearchCheckboxes
}

export interface SearchActionParameters {
  /**
   * Search keyword
   */
  search: string
  searchType: SearchType
}

export interface DeletedUsersSearchActionParameters {
  /**
   * Search keyword
   */
  search: string
  searchType: SearchType
}

export interface SearchError extends SearchCommon {
  errorMessage: string
}

export function isSearchError(config: SearchConfig | SearchError): config is SearchError {
  return (config as SearchError).errorMessage !== undefined
}

export class UserSearch {
  private keywordMinLength: number = 3
  private keywordMaxLength: number = 255

  private checkKeywordMinLength(keyword: string): boolean {
    return keyword.length >= this.keywordMinLength
  }

  private checkKeywordMaxLength(keyword: string): boolean {
    return keyword.length <= this.keywordMaxLength
  }

  public getSearchOptions(
    keyword: string,
    permissions: string[],
    userChosenSearchType?: SearchType,
  ): SearchConfig | SearchError {
    //this 'trims' the whitespaces before and after the search string
    keyword = keyword.trim()

    if (!this.checkKeywordMinLength(keyword)) {
      // testcase KeywordTooShort
      return {
        keyword: keyword,
        errorMessage: 'Search keyword too short, must be at least ' + this.keywordMinLength + ' characters.',
      }
    }
    if (!this.checkKeywordMaxLength(keyword)) {
      // testcase KeywordTooLong
      return {
        keyword: keyword,
        errorMessage: 'Search keyword too long, maximum length is ' + this.keywordMaxLength + ' characters.',
      }
    }

    const isValidUuid = uuidValidate(keyword)
    const fuzzySearchAccess = permissions.includes('allowPartialSearchAccess')
    const fullSearchAccess = permissions.includes('rolesOuraUsersAdmin')
    const searchWithEmailAccess = permissions.includes('allowPersonalDataAccess')
    const noSearchPermissions = !fuzzySearchAccess && !fullSearchAccess && !searchWithEmailAccess

    const keywordRegexp = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~@-]+$')
    const keywordIsValid = keywordRegexp.test(keyword)
    const checkboxesDefault: SearchCheckboxes = {
      fuzzyMatching: {
        enabled: false,
      },
      deletedAccountsOnly: {
        enabled: false,
      },
    }

    if (!keywordIsValid) {
      // testcase KeywordInvalidChars
      return {
        keyword: keyword,
        errorMessage: 'Search keyword has invalid characters.',
        checkboxes: checkboxesDefault,
      }
    }

    // regex used in type="email" from W3C
    const emailRegexp = emailPattern
    const regexp = new RegExp(emailRegexp)
    const keywordIsEmailAddress = regexp.test(keyword)

    if (isValidUuid) {
      // 1. If the search string matches UUID format, show "Press enter to search the user UUID"
      // testcase ValidUuid
      return {
        keyword: keyword,
        searchType: SearchType.SEARCH_TYPE_UUID,
        confirmationMessage: 'Press enter to search with user UUID.',
        checkboxes: {
          fuzzyMatching: {
            enabled: false,
          },
          deletedAccountsOnly: {
            enabled: true,
          },
        },
      }
    }

    if (!isValidUuid && noSearchPermissions) {
      // 2. If the search string does not match UUID format and the user has no permissions to search with email,
      if (!keywordIsEmailAddress) {
        // 2.1. if the search string does not match email format,
        // disable the search button and show in red text this information to the user
        // testcase InvalidUuidAndNoEmailSearchPermission
        return {
          keyword: keyword,
          errorMessage: 'Keyword does not match UUID format, email search limited to shared accounts.',
          checkboxes: checkboxesDefault,
        }
      } else {
        // 2.2 if the search string matches email format, allow shared accounts to be searched.
        // testcase InvalidUuidSharedAccountSearch
        return {
          keyword: keyword,
          searchType: SearchType.SEARCH_TYPE_EMAIL_EXACT,
          confirmationMessage: 'Press enter to search shared accounts.',
          checkboxes: checkboxesDefault,
        }
      }
    }

    if (keywordIsEmailAddress && userChosenSearchType == SearchType.SEARCH_TYPE_EMAIL_DELETED_EXACT) {
      return {
        keyword: keyword,
        searchType: SearchType.SEARCH_TYPE_EMAIL_DELETED_EXACT,
        confirmationMessage: 'Press enter to search deleted accounts with exact email match.',
        checkboxes: {
          fuzzyMatching: {
            enabled: false,
          },
          deletedAccountsOnly: {
            enabled: true,
          },
        },
      }
    }

    if (keywordIsEmailAddress && searchWithEmailAccess) {
      // 6. If the search string contains @-character and user has email search permissions,
      // show "Press enter to search with exact email match"
      if (fuzzySearchAccess) {
        if (userChosenSearchType && userChosenSearchType == SearchType.SEARCH_TYPE_EMAIL_FUZZY) {
          // testcase FuzzySearchSuggestChosen
          return {
            keyword: keyword,
            searchType: SearchType.SEARCH_TYPE_EMAIL_FUZZY,
            confirmationMessage: 'Press enter to search with fuzzy matching.',
            checkboxes: {
              fuzzyMatching: {
                enabled: true,
              },
              deletedAccountsOnly: {
                enabled: false,
              },
            },
          }
        }
        // testcase FuzzySearchSuggest
        return {
          keyword: keyword,
          searchType: SearchType.SEARCH_TYPE_EMAIL_EXACT,
          confirmationMessage: 'Press enter to search with exact email match.',
          checkboxes: {
            fuzzyMatching: {
              enabled: true,
            },
            deletedAccountsOnly: {
              enabled: true,
            },
          },
        }
      }
      // testcase ExactEmailMatch
      return {
        keyword: keyword,
        searchType: SearchType.SEARCH_TYPE_EMAIL_EXACT,
        confirmationMessage: 'Press enter to search with exact email match.',
        checkboxes: {
          fuzzyMatching: {
            enabled: false,
          },
          deletedAccountsOnly: {
            enabled: true,
          },
        },
      }
    }

    if (fullSearchAccess) {
      // testcase PartialEmailAddressAdmin
      return {
        keyword: keyword,
        searchType: SearchType.SEARCH_TYPE_EMAIL_PARTIAL,
        confirmationMessage: 'Press enter to search with partial email address.',
        checkboxes: checkboxesDefault,
      }
    }

    if (!keywordIsEmailAddress && !fullSearchAccess) {
      // we never get here.

      // 3. If the search string does not contain @-character and the user does not have full search permissions,
      // disable the search button and show in red text to user that they must type in either uuid
      // (or email if they have permissions for email search)
      if (searchWithEmailAccess) {
        return {
          keyword: keyword,
          errorMessage: "You don't have full search permission. Enter UUID or email address.",
          checkboxes: checkboxesDefault,
        }
      }
      return {
        keyword: keyword,
        errorMessage: 'You don\'t have full or email search permission. Enter UUID."',
        checkboxes: checkboxesDefault,
      }
    }

    return {
      keyword: keyword,
      errorMessage: 'No search criteria matched.',
      checkboxes: checkboxesDefault,
    }
  }
}
