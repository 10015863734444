<template>
  <div>
    <!-- TODO: Change visible diagnostic to correct ones when all backend diagnostics are done -->

    <DiagnosticsResult
      class="b-8"
      :user="user"
      :ring="ring"
      :sidecar="false"
      :visible-diagnostics="['battery', 'ring-connectivity-diagnostic']"
      :sub-components="diagnosticsSubComponents"
    />

    <template v-if="user && ring">
      <GenericGraph
        class="mb-8"
        title="Battery charge level"
        :graphs="['batteryLevelAssa', 'batteryHealth', 'spo2usage', 'whrUsage']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryChargeLevelGraphStartDate"
      />

      <GenericGraph
        v-if="$featureEnabled('ringLifetimeFromAssa')"
        class="mb-8"
        title="Battery life estimate"
        :graphs="['batteryLifeTrend']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryLifeGraphStartDate"
        :autoscale="true"
        :graph-height="400"
      />
      <RingLifetime v-else class="mb-8" :data="events" :ring="ring" />
    </template>

    <ClientInfo v-if="ring && user" class="mb-8" :member="user" :ring="ring" />
  </div>

  <SummaryReportV1
    v-if="user"
    class="mb-8"
    :uuid="user.uuid"
    :ring="ring"
    :rings="user.rings"
    :summary-fields="batteryInfoFields"
    :show-filters="false"
  />
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { batteryInfoSummaryFields } from '#views/members/constants'

  import { DiagnosticsSubComponents } from '#views/members/account/DiagnosticsResult.vue'

  import { TimelineStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class BatteryDetails extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]

    private readonly dateFormat = 'YYYY-MM-DD'

    public timelineStore = new TimelineStore()

    public graphEndDate = dayjs().format(this.dateFormat)
    public batteryChargeLevelGraphStartDate = dayjs().subtract(14, 'day').format(this.dateFormat)

    public openDiagnostics = false

    public batteryInfoFields = batteryInfoSummaryFields

    @Watch('ring', { immediate: true })
    protected onRingChanged() {
      this.fetchBatteryHealth()
    }

    public get events() {
      return this.timelineStore.lifetime
    }

    public get diagnosticsSubComponents() {
      return [
        DiagnosticsSubComponents.BatteryHealthy,
        DiagnosticsSubComponents.Warranty,
        DiagnosticsSubComponents.Version,
        DiagnosticsSubComponents.SelfTestHealth,
      ]
    }

    public get batteryLifeGraphStartDate() {
      return dayjs(this.ring?.registrationDate).format(this.dateFormat)
    }

    private fetchBatteryHealth() {
      if (this.user?.uuid && this.ring?.serialNumber) {
        this.timelineStore.getLifetimeEvents({
          uuid: this.user.uuid,
          ringSerial: this.ring.serialNumber,
          registrationDate: this.ring.registrationDate,
        })
      }
    }
  }

  export default toNative(BatteryDetails)
</script>
