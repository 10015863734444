<template>
  <div>
    <v-card :loading="dataWait" style="overflow: hidden; width: 100%">
      <v-card-title>
        <div class="text-overline">ASSA data viewer</div>
      </v-card-title>

      <v-card-text>
        <v-toolbar>
          <v-row align="center">
            <v-col cols="5">
              <v-autocomplete
                v-model="selected"
                :items="models"
                label="Select an ASSA model"
                hide-details
                dense
                clearable
              />
            </v-col>
            <v-col cols="5">
              <DateRangePicker
                v-model:start-date="startDate"
                v-model:end-date="endDate"
                :max-day-range="7"
                @valid="datesAreValid = $event"
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <v-btn :disabled="searchDisabled" color="primary" variant="elevated" @click="search()">Run query</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-row>
          <v-col cols="12">
            <v-alert v-if="error" type="error" class="mb-8" style="position: relative">
              {{ error }}
            </v-alert>

            <JSONView v-else :data="sortedData" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import sortKeys from 'sort-keys'

  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { AppStore, DataBrowserStore } from '#stores'

  @Component
  class AssaDataViewer extends mixins(DateTime) {
    @Prop() public uuid!: string
    @Prop() public ringSerial!: string

    public datesAreValid = true

    public startDate = ''
    public endDate = ''

    public selected: string = ''

    public dataBrowserStore = new DataBrowserStore()
    public appStore = new AppStore()

    public get dataWait(): boolean {
      return this.dataBrowserStore.dataWait
    }

    public get models(): string[] {
      return this.dataBrowserStore.assaModels
    }

    public get data() {
      return this.dataBrowserStore.assaData[this.uuid]
    }

    public get sortedData(): any | null {
      return this.data ? sortKeys(this.data, { deep: true }) : null
    }

    public get error() {
      return this.dataBrowserStore.assaDataError
    }

    public mounted() {
      this.startDate = this.$dayjs().format('YYYY-MM-DD')
      this.endDate = this.$dayjs().format('YYYY-MM-DD')

      this.dataBrowserStore.getModels()
      this.dataBrowserStore.assaDataError = ''
    }

    public async search() {
      await this.dataBrowserStore.getData(this.selected, {
        timestampFrom: this.startDate,
        timestampTo: this.endDate,
        userUuids: this.uuid,
      })
    }

    public get searchDisabled() {
      return !this.selected || !this.datesAreValid || this.dataWait
    }
  }

  export default toNative(AssaDataViewer)
</script>
