import { getDaysBetween } from '#utils/datetime/calculate'

import { RingHardwareSyncInfo } from '#types'

export function extractLastSyncBanner(lastSyncedAt: string | null): RingHardwareSyncInfo {
  let syncMessage = ''
  let alertColor = ''
  let icon = ''
  let lastSyncDaysAgo: number = -1

  //if lastSyncDate is null, then lastSyncDaysAgo will be -1 and return else block
  if (lastSyncedAt) {
    lastSyncDaysAgo = getDaysBetween(new Date(lastSyncedAt), new Date())
  }

  if (lastSyncDaysAgo == 1 || lastSyncDaysAgo == 0) {
    syncMessage +=
      lastSyncDaysAgo == 1
        ? `- was within the last 48 hours - ${lastSyncDaysAgo} day ago`
        : `- was within the last 48 hours - ${lastSyncDaysAgo} days ago`
    alertColor = 'green'
    icon = 'mdi-calendar-check'
  } else if (lastSyncDaysAgo >= 2) {
    syncMessage += `- was more than 48 hours ago (${lastSyncDaysAgo} days ago)`
    alertColor = 'yellow'
    icon = 'mdi-calendar-remove'
  } else {
    syncMessage = 'Last sync date cannot be determined'
    alertColor = 'grey'
    icon = 'mdi-clock-alert'
  }
  return {
    lastSyncDate: lastSyncedAt ? lastSyncedAt : null,
    syncMessage: syncMessage,
    alertColor: alertColor,
    clockIcon: icon,
  }
}
