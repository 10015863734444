<template>
  <v-dialog :model-value="true" persistent width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Bulk create Oura accounts</span>
        <v-spacer />
      </v-card-title>

      <v-card-text class="pb-0">
        <v-container>
          <v-row align="center">
            <v-col cols="2">
              <p class="text-overline">Name:</p>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model.trim="bulkUser.name" autofocus />
            </v-col>
          </v-row>

          <p class="text-overline">Emails</p>

          <v-radio-group v-model="bulkUser.emailType">
            <v-radio value="upload" class="mt-n2" label="Use email address list" />
            <div v-if="bulkUser.emailType === 'upload'" cols="6" class="mt-n4 mb-3">
              <v-textarea
                v-model="emailTextarea"
                label="Add emails ..."
                name="emails-input"
                :messages="['Seperate email with whitespace, comma or new line']"
              />
            </div>

            <v-radio label="Generate email addresses" value="generate" />
            <div v-if="bulkUser.emailType === 'generate'" class="pl-7">
              <v-alert variant="outlined" color="primary">
                <v-row class="justify-space-between">
                  <v-col cols="5" sm="5" md="5">
                    <v-text-field
                      v-model="prefix"
                      variant="underlined"
                      label="Email prefix"
                      @change="emailGenerate()"
                    />
                  </v-col>
                  <v-col cols="5" sm="5" md="5">
                    <v-text-field v-model="start" type="number" label="Start #:" @change="emailGenerate()" />
                  </v-col>
                </v-row>

                <v-row class="justify-space-between my-n2">
                  <v-col cols="5" sm="5" md="5">
                    <v-text-field
                      v-model="domain"
                      variant="underlined"
                      label="Email domain"
                      :messages="['Example: ouraring.studies']"
                      @change="emailGenerate()"
                    />
                  </v-col>
                  <v-col cols="5" sm="5" md="5">
                    <v-text-field v-model="count" type="number" min="1" label="Count #:" @change="emailGenerate()" />
                  </v-col>
                </v-row>
                <v-row v-if="emailExample.length">
                  <v-col cols="2"><p>Results:</p></v-col>
                  <v-col>
                    <p v-for="(email, index) in emailExample" :key="index" class="ma-0 na-0">{{ email }}</p>
                  </v-col>
                </v-row>
              </v-alert>
            </div>
          </v-radio-group>

          <p class="text-overline">Settings</p>

          <v-checkbox
            v-model="bypassEmail"
            label="Email addresses do not exist"
            :disabled="bulkUser.emailType === 'generate'"
          />

          <p class="text-overline">Passwords</p>

          <v-radio-group v-model="bulkUser.password">
            <v-radio
              v-for="pw in passwordTypes"
              :key="pw.value"
              :label="pw.name"
              :value="pw.value"
              :disabled="bypassEmail && pw.value === 'email'"
            />
          </v-radio-group>

          <p class="text-overline">Research Labels</p>

          <v-autocomplete v-model="bulkUser.researchLabel" item-title="name" :items="labels" class="pl-4 pr-4" />

          <p class="mt-2 mr-n4 mb-0 text-primary text-body-2">
            <strong>Important note:</strong>
            The resulting CSV file will only be available for 1 week. Remember to store it somewhere safe
          </p>
        </v-container>
      </v-card-text>

      <v-alert v-if="!!error" tile class="v-sheet--tile py-4" type="error" color="red">
        <span class="text-white">{{ error }}</span>
      </v-alert>

      <v-dialog v-model="save" width="500">
        <v-card>
          <v-card-title>User account creation</v-card-title>
          <v-card-text>
            <p>
              Are you sure you want to create a bulk accounts batch with name
              <strong>"{{ bulkUser.name }}"</strong>
              - {{ bulkUser.userCounts }} new Oura user accounts and
              {{
                bulkUser.password === 'generate'
                  ? 'password reset link generated'
                  : 'password reset link sent to email'
              }}? This action cannot be undone
            </p>
            <p>
              Confirm by entering the name of new bulk accounts batch:
              <v-text-field v-model="confirmName" type="text" label="Name of new bulk accounts batch" />
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="save = false">Cancel</v-btn>

            <v-btn
              color="primary"
              :disabled="!confirmName || confirmName.trim() !== bulkUser.name.trim()"
              @click="confirm()"
            >
              Confirm
              <v-progress-circular v-if="dataWait" indeterminate color="white" class="ml-2" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn variant="text" class="mr-2" @click="closeDialog()">Cancel</v-btn>
        <v-btn
          variant="text"
          color="primary"
          :disabled="!bulkUser.name || !bulkUser.emailType || !bulkUser.password"
          @click="create()"
        >
          Create users
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { passwordTypes } from '#views/admin/constants'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AdminStore, TasksStore } from '#stores'

  @Component
  class CreateAccounts extends Vue {
    public adminStore = new AdminStore()

    public tasksStore = new TasksStore()

    public bulkUser: any = {
      name: '',
      emailFile: null,
      password: '',
      emailType: '',
      userCounts: 0,
      researchLabel: '',
    }

    public count = 0
    public start = 0

    public error = ''
    public prefix = ''
    public domain = ''
    public confirmName = ''
    public emailTextarea: string = ''

    public save = false
    public bypassEmail = false

    public emailExample: string[] = []

    public passwordTypes: { name: string; value: string }[] = passwordTypes

    private env = import.meta.env.VITE_APP_ENV

    private emailGenerated: { email: string }[] | null = null

    public mounted() {
      this.adminStore.adminLabels()
    }

    public get labels() {
      return this.adminStore.labels
    }

    public get dataWait() {
      return this.tasksStore.dataWait
    }

    @Emit('close')
    public emitClose() {
      return
    }

    @Watch('bulkUser.emailType')
    protected onEmailTypeChange(_val: string, _oldVal: string) {
      if (this.bulkUser.emailType === 'generate') {
        this.bypassEmail = true
      }
    }

    @Watch('bypassEmail')
    protected onBypassEmailChange(_val: string, _oldVal: string) {
      this.bulkUser.password = passwordTypes[1].value
    }

    @Watch('prefix')
    protected onPrefixChange(_val: string, _oldVal: string) {
      this.emailGenerate()
    }

    @Watch('domain')
    protected onDomainChange(_val: string, _oldVal: string) {
      this.emailGenerate()
    }

    @Watch('start')
    protected onStartChange(_val: number, _oldVal: number | null) {
      this.emailGenerate()
    }

    @Watch('count')
    protected onCountChange(_val: number, _oldVal: number | null) {
      this.emailGenerate()
    }

    @Watch('bulkUser.researchLabel')
    protected onLabel(_val: string, _old: string) {
      this.bulkUser.label = _val
    }

    public create() {
      this.error = ''
      if (this.bulkUser.name && this.bulkUser.password) {
        let emailTextareaError: string[] = []

        this.bulkUser.emailFile =
          this.bulkUser.emailType === 'generate'
            ? this.emailGenerated
            : this.emailTextarea
                .trim()
                .split(/[,\n\s]+/)
                .reduce((acc: { email: string }[], cur: string) => {
                  if (cur && isEmailValid(cur, this.env)) {
                    acc.push({ email: cur })
                  } else if (cur) {
                    emailTextareaError.push(cur)
                  }
                  return acc
                }, [])

        this.bulkUser.userCounts = this.bulkUser.emailType === 'generate' ? this.count : this.bulkUser.emailFile.length

        if (
          emailTextareaError.length ||
          (!['preview', 'testing'].includes(this.env) && this.bulkUser.userCounts > 10000) ||
          (['preview', 'testing'].includes(this.env) && this.bulkUser.userCounts > 10)
        ) {
          this.error = emailTextareaError.length
            ? `These added emails are in invalid format: ${emailTextareaError.join(',')}. ${getEmailValidationErrorMessage(this.env)}`
            : `${
                ['preview', 'testing'].includes(this.env)
                  ? 'Exceeding number emails limit of 10'
                  : 'Exceeding number emails limit of 10000'
              }`
        } else if (this.bulkUser.userCounts < 1) {
          this.error = 'No users would be created with given input'
        } else {
          this.save = true
        }
      } else {
        this.error = 'Please add all fields!'
      }
    }

    public async confirm() {
      const blob = new Blob([JSON.stringify(this.bulkUser.emailFile)], {
        type: 'application/json',
      })

      const res: any = await this.tasksStore.createBulkUser({
        name: this.bulkUser.name,
        emailFile: new File([blob], 'emails.json'),
        sendEmail: this.bulkUser.password === 'email',
        bypassEmailVerification: this.bypassEmail,
        userCounts: this.bulkUser.userCounts + '',
        label: this.bulkUser?.researchLabel ? this.bulkUser.researchLabel : '',
      })
      if (res?.data) {
        this.closeDialog()
      } else {
        this.save = false

        this.error = 'Unknown error occured while processing the request'
      }
    }

    public closeDialog() {
      this.save = false

      this.confirmName = ''

      this.emailTextarea = ''

      this.emailGenerated = null

      this.emailExample = []

      this.bypassEmail = false

      this.error = ''

      this.confirmName = ''

      this.bulkUser = {
        name: '',
        emailFile: null,
        password: '',
        emailType: '',
        userCounts: 0,
        adminLabel: '',
      }

      this.prefix = ''
      this.domain = ''
      this.start = 0
      this.count = 0

      this.emitClose()
    }

    public emailGenerate() {
      if (this.prefix && this.domain && this.count) {
        let emails: any[] = []

        const endNumber = +this.count + +this.start - 1

        let startStrLength: number = this.start.toString().length
        let prefixOrder: string = ''

        for (let i = this.start; i <= endNumber; i++) {
          const idxString: number = i.toString().length

          if (startStrLength >= idxString) {
            prefixOrder = new Array(startStrLength - idxString).fill(0).join('')
          }

          emails.push({ email: `${this.prefix}${prefixOrder}${i}@${this.domain}` })
        }

        this.emailExample = emails.length
          ? [
              emails[0]?.email,
              emails.length > 2 ? emails[1]?.email : '',
              emails.length > 3 ? '...' : '',
              this.count > 1 ? emails[emails.length - 1]?.email : '',
            ]
          : []

        this.emailGenerated = emails
      }
    }
  }

  export default toNative(CreateAccounts)
</script>
