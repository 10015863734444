<template>
  <v-banner
    v-if="$featureEnabled('ringLastSyncBanner')"
    single-line
    :color="lastSyncBanner.alertColor"
    density="compact"
    :icon="lastSyncBanner.clockIcon"
    class="pt-3"
    :text="syncMessage"
  />
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { extractLastSyncBanner } from '#utils/user/diagnosticBanner'

  import { Ring, RingHardwareSyncInfo } from '#types'

  @Component
  class LastSyncInfo extends mixins(DateTime) {
    @Prop() public ring!: Ring

    public get syncMessage(): string {
      return this.lastSyncBanner.lastSyncDate
        ? `Last sync:  ${this.formatDateTime(this.lastSyncBanner.lastSyncDate, 'DD MMM YYYY, HH:mm:ss')} `
        : 'Last sync date cannot be determined at this time'
    }

    public get lastSyncBanner(): RingHardwareSyncInfo {
      return extractLastSyncBanner(this.ring.lastSyncedAt)
    }
  }

  export default toNative(LastSyncInfo)
</script>
