import { DeviceInfo, Nullable, RingDetails, WarrantyData } from '#types'

export function formatColor(color: string | null) {
  if (color) {
    return color.charAt(0).toUpperCase() + color.slice(1)
  }
  return 'Unknown'
}

export function formatDesign(design: string | null) {
  if (design) {
    return design.charAt(0).toUpperCase() + design.slice(1)
  }
  return 'Unknown'
}

export function extractDeviceInfoFromRingAndWarrantyDetails(
  ringDetails: RingDetails | null,
  warrantyData: WarrantyData | null,
): DeviceInfo {
  const deviceInfo: DeviceInfo = { description: null }
  let ringSerial: Nullable<string> = null
  let ringFactory: Nullable<string> = null
  let ringHwMajor: Nullable<string> = null
  let ringHwMinor: Nullable<string> = null
  let ringProduct: Nullable<string> = null
  let ringSize: Nullable<number> = null
  let ringColor: Nullable<string> = null
  let ringDesign: Nullable<string> = null
  let ringManufacturingYear: Nullable<number> = null
  let ringManufacturingWeek: Nullable<number> = null
  let ringMacAddress: Nullable<string> = null

  if (warrantyData) {
    ringSerial = warrantyData.ringset.ringSerialNumber
    if (warrantyData.ringset.ringSerialInfo) {
      ringFactory = warrantyData.ringset.ringSerialInfo.factory ?? null
      ringHwMajor = warrantyData.ringset.ringSerialInfo.hwMajor ?? null
      ringHwMinor = warrantyData.ringset.ringSerialInfo.hwMinor ?? null
      ringProduct = warrantyData.ringset.ringSerialInfo.product ?? null
      ringSize = warrantyData.ringset.ringSerialInfo.size ?? null
      ringManufacturingYear = warrantyData.ringset.ringSerialInfo.manufacturingYear ?? null
      ringManufacturingWeek = warrantyData.ringset.ringSerialInfo.manufacturingWeek ?? null
    }
  }
  if (ringDetails) {
    ringSerial = ringDetails.serialNumber
    ringColor = formatColor(ringDetails.color)
    ringDesign = formatDesign(ringDetails.design)
    ringMacAddress = ringDetails.macAddress
    if (ringDetails.ringSerialInfo) {
      ringFactory = ringDetails.ringSerialInfo.factory ?? null
      ringHwMajor = ringDetails.ringSerialInfo.hwMajor ?? null
      ringHwMinor = ringDetails.ringSerialInfo.hwMinor ?? null
      ringProduct = ringDetails.ringSerialInfo.product ?? null
      ringSize = ringDetails.ringSerialInfo.size ?? null
      ringManufacturingYear = ringDetails.ringSerialInfo.manufacturingYear ?? null
      ringManufacturingWeek = ringDetails.ringSerialInfo.manufacturingWeek ?? null
    }
  }

  if (ringSerial && (ringDetails || warrantyData)) {
    deviceInfo['ring'] = {
      serialNumber: ringSerial,
      factory: ringFactory,
      hwMajor: ringHwMajor,
      hwMinor: ringHwMinor,
      product: ringProduct,
      size: ringSize,
      color: ringColor,
      design: ringDesign,
      manufacturingYear: ringManufacturingYear,
      manufacturingWeek: ringManufacturingWeek,
      macAddress: ringMacAddress,
      macAddressTitle: 'MAC:',
      serialNumberTitle: 'RING S/N:',
    }
  }

  if (warrantyData && warrantyData?.ringset && warrantyData?.ringset?.chargerSerialInfo) {
    const ringset = warrantyData.ringset
    deviceInfo['charger'] = {
      serialNumber: ringset.chargerSerialNumber ?? '',
      factory: ringset.chargerSerialInfo?.factory ?? null,
      hwMajor: ringset.chargerSerialInfo?.hwMajor ?? null,
      hwMinor: ringset.chargerSerialInfo?.hwMinor ?? null,
      product: ringset.chargerSerialInfo?.product ?? null,
      size: ringset.chargerSerialInfo?.size ?? null,
      color: 'Black', // charger color is hard-coded to "Black"
      design: 'Original', // charger design is hard-coded to "Original"
      manufacturingYear: ringset.chargerSerialInfo?.manufacturingYear ?? null,
      manufacturingWeek: ringset.chargerSerialInfo?.manufacturingWeek ?? null,
      macAddress: null,
      macAddressTitle: null,
      serialNumberTitle: 'CHARGER S/N:',
    }

    const items = warrantyData.fulfillment?.items
    const findEQX = items?.find((item) => item.description.includes('EQX'))?.description
    if (findEQX) {
      deviceInfo['description'] = findEQX
    } else if (items?.length && items[0].description) {
      deviceInfo['description'] = items[0].description
    }
  }

  return deviceInfo
}
