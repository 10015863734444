import JSON5 from 'json5'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { Nullable, PlotlyFigure } from '#types'

@Store()
export class ArrhythmiaStore extends BaseStore {
  public dataWait = false

  public ibiPlotData: Nullable<PlotlyFigure> = null
  public ppgPlotData: Nullable<PlotlyFigure[]> = []

  public async getIbiPlotData(uuid: string, timestampTo: string) {
    this.dataWait = true
    this.ibiPlotData = null

    const url = `/api/v1/users/${uuid}/arrhythmia-ibi`
    const params = { timestampTo: timestampTo + 'T23:59:59' }
    const response = await this.makeRequest({ method: 'get', url: url, params: params }, 'getArrhythmiaIbiData')
      .then((value: AxiosResponse | null) => {
        return value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    const ibiPlotDataResponse: string | undefined = response?.graph
    if (ibiPlotDataResponse) {
      this.ibiPlotData = JSON5.parse(window.atob(ibiPlotDataResponse))
    }

    this.dataWait = false
  }

  public async getPPGPlotData(uuid: string, timestampTo: string) {
    this.dataWait = true
    this.ppgPlotData = []

    const url = `/api/v1/users/${uuid}/arrhythmia-ppg`
    const params = { timestampTo: timestampTo + 'T23:59:59' }
    const response = await this.makeRequest({ method: 'get', url: url, params: params }, 'getArrhythmiaPpgData')
      .then((value: AxiosResponse | null) => {
        return value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    const ppgDataResponse: any = response?.figures
    if (ppgDataResponse) {
      for (const graph in ppgDataResponse) {
        this.ppgPlotData.push(JSON5.parse(window.atob(ppgDataResponse[graph]?.graph)))
      }
    }

    this.dataWait = false
  }

  public resetData() {
    this.ibiPlotData = null
    this.ppgPlotData = []
  }
}
