<template>
  <div class="pa-4">
    <div class="mb-n2">
      <span class="text-overline">Oura accounts</span>

      <v-icon size="x-small" color="grey" class="mb-1 ml-2" @click="openDialog('info')">mdi-information-outline</v-icon>
    </div>

    <v-sheet class="pa-3 mt-n2">
      <div v-for="account in supportAccounts" :key="account.email">
        <v-chip
          :id="Math.random().toString()"
          link
          label
          variant="outlined"
          class="md-n8 ps-2"
          style="width: 100%"
          @click="moveToAccountPage(account.userUid)"
        >
          {{ account.email }}
        </v-chip>
      </div>

      <!-- <v-checkbox
        v-model="allowOuraringData"
        class="mt-n2"
        color="pink"
        label="Allow other Ouranians access my ouraring accounts data"
      ></v-checkbox>

      <v-checkbox
        v-model="allowPersonalData"
        class="mt-n2"
        color="pink"
        :disabled="!personalEmail"
        label="Allow other Ouranians access my personal accounts data"
      ></v-checkbox> -->

      <div class="d-flex">
        <v-spacer />
        <v-btn class="mt-8 self-justify-end" color="primary" :disabled="!supportConsent" @click="openDialog('add')">
          Add account
        </v-btn>
        <v-spacer />
      </div>
    </v-sheet>

    <v-dialog v-if="addDialog || infoDialog" v-model="dialogVisible" width="800" @after-leave="closeDialog()">
      <v-card>
        <v-card-title class="headline grey lighten-2">{{ addDialog ? 'Add Account' : 'Oura Accounts' }}</v-card-title>

        <v-card-text class="pt-5">
          You have full access to accounts that has been created with your ouraring email (or any '+' variant of that
          email). You can add accounts that you own or fellow Ouranian's if you have got prior permission to add it. You
          can see all your accounts on the 'Oura users' page.
          <br />
          <br />
          <div v-if="supportConsent">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <b><div v-html="supportConsent.content.replace(/\\n/g, '<br /><br />')" /></b>
          </div>

          <div v-if="addDialog">
            <v-form v-model="emailValid">
              <v-text-field
                v-model="email"
                label="Email address"
                hide-details="auto"
                :rules="emailRules"
                placeholder="Enter the Oura account email address to add"
              />
            </v-form>

            <div v-if="errorText" class="red--text pb-8">{{ errorText }}</div>

            When you click 'Request access' an email will be sent to the given email address where the permission to
            allow you access the account will need to be confirmed.
          </div>
          <!-- <br />
          <br />
          <div class="text-button">Allowing access</div>
          By giving consent for other Ouranians (Oura employees) to access your accounts data you will be helping with
          the development of the Oura software. You can change this setting at any time. What data can be seen by the
          other Ouranians depends on the permissions they have, but
          <b>you should only allow this if you are ok with any Ouranian seeing your full data</b>
          .-->
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn v-if="addDialog" color="info" variant="text" @click="closeDialog()">Cancel</v-btn>
          <v-btn v-if="infoDialog" color="info" variant="text" @click="closeDialog()">Close</v-btn>

          <v-btn color="info" variant="text" :disabled="!email || !emailValid" @click="addAccount()">
            Request access
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AppStore } from '#stores'

  @Component
  class PersonalEmail extends Vue {
    public appStore = new AppStore()

    public email = ''
    public errorText = ''

    public addDialog = false
    public infoDialog = false
    public dialogVisible = false
    public emailValid = false
    private env = import.meta.env.VITE_APP_ENV

    public emailRules = [(v: string) => isEmailValid(v, this.env) || getEmailValidationErrorMessage(this.env)]

    public get supportConsent() {
      return this.appStore.consent
    }

    public get supportAccounts() {
      return this.appStore.accounts
    }

    public mounted() {
      this.appStore.getOuraAccounts()
    }

    public async addAccount() {
      const res = await this.appStore.addOuraAccount(this.email)

      if (res.status !== 204) {
        this.errorText = 'Failed to send the email, perhaps the account does not exist?'
      } else {
        this.errorText = ''
        this.addDialog = false
        this.email = ''
      }
    }

    public moveToAccountPage(userUid: string) {
      this.$router.push({ path: `/users/${userUid}` }).catch((_err) => {})
    }

    public openDialog(dialog: 'add' | 'info') {
      if (dialog == 'add') {
        this.addDialog = true
      } else if (dialog == 'info') {
        this.infoDialog = true
      }
      this.dialogVisible = true
    }

    public closeDialog() {
      this.addDialog = false
      this.infoDialog = false
      this.dialogVisible = false
    }
  }

  export default toNative(PersonalEmail)
</script>

<style lang="scss" scoped></style>
