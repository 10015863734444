import { UserStore } from './user'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { extractContactDetailsFromResponse, extractPaymentMethodsFromResponse } from '#utils/subscription/extract'

import { BaseStore } from '#stores/base'

import {
  AddressData,
  CompensateSubscriptionBaseRequest,
  CompensateSubscriptionWithEscalationReasonRequest,
  ContactDetails,
  ContactType,
  CreditMemo,
  Invoice,
  PaymentMethod,
  PaymentsInfo,
  SubscriptionActionParams,
  SubscriptionAuditLog,
  SubscriptionHistoryEvent,
  Subscriptions,
  UpgradeSubscriptionActionData,
} from '#types'

const REQUEST_SOURCE_START_TRIAL_REQUEST = 'startTrial'
const REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_REQUEST = 'upgradeSubscription'
const REQUEST_SOURCE_START_TRIAL_ACTION = 'startTrialAction'
const REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION = 'upgradeSubscriptionAction'
const REQUEST_SOURCE_RESET_DUNNING_REQUEST = 'resetDunning'
const REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST = 'updateAddress'
const REQUEST_SOURCE_RESET_SUBSCRIPTION_REQUEST = 'resetSubscription'
const REQUEST_SOURCE_CANCEL_SUBSCRIPTION_REQUEST = 'cancelSubscription'
const REQUEST_SOURCE_EXTEND_SUBSCRIPTION_REQUEST = 'extendSubscription'

@Store()
export class SubscriptionStore extends BaseStore {
  public dataWait = false

  public extendSubscriptionError = ''

  public invoices: Invoice[] = []
  public payments: PaymentsInfo[] = []
  public creditMemos: CreditMemo[] = []

  public invoice: Blob | null = null
  public creditMemo: Blob | null = null

  public subscriptions: Subscriptions = {
    subscriptions: [],
    isPaymentMethodSet: false,
    dunningLevel: 0,
    defaultPaymentMethodCardExpired: false,
  }

  public paymentMethods: PaymentMethod[] = []
  public contactDetails: ContactDetails = {
    billToContact: null,
    shipToContact: null,
  }
  public subscriptionAuditLogs: SubscriptionAuditLog[] = []
  public subscriptionHistoryEvents: SubscriptionHistoryEvent[] = []

  public waitingForStartTrialData(): boolean {
    return this.waitingForData([REQUEST_SOURCE_START_TRIAL_ACTION])
  }

  public waitingForUpgradeLifetimeData(): boolean {
    return this.waitingForData([REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION])
  }

  public waitingForResetDunning(): boolean {
    return this.waitingForData([REQUEST_SOURCE_RESET_DUNNING_REQUEST])
  }
  public waitingForResetSubscription(): boolean {
    return this.waitingForData([REQUEST_SOURCE_RESET_SUBSCRIPTION_REQUEST])
  }
  public waitingForAddressUpdate(): boolean {
    return this.waitingForData([REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST])
  }

  public async getPayments(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    if (data.initial) {
      this.payments = []
      this.dataWait = true
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/payments` },
      'getPayments',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.payments = response?.data?.payments || []

    if (data.initial) {
      this.dataWait = false
    }
  }

  public async getPaymentMethods(data: { userId: string }) {
    const userStore = new UserStore()

    this.paymentMethods = []
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/payment-methods` },
      'getPaymentMethods',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data) {
      this.paymentMethods = extractPaymentMethodsFromResponse(response?.data)
    }
    this.dataWait = false
  }

  public async removePaymentMethod(data: { userId: string; paymentMethodId: string }) {
    const userStore = new UserStore()

    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'delete', url: `/api/v1/users/${data.userId}/payment-methods/${data.paymentMethodId}` },
      'removePaymentMethod',
      data.userId,
      userStore.user?.uuid,
    )
    this.dataWait = false
    await this.getPaymentMethods(data)
    return response?.status
  }

  public async refundPayment(data: {
    refundReasonDetail: string
    paymentId: string
    refundReason: string
    issueStartDate: string
    userId: any
  }) {
    const userStore = new UserStore()

    this.dataWait = true
    // Request data should be typed
    const payload = data.refundReasonDetail
      ? {
          refundReason: data.refundReason,
          issueStartDate: data.issueStartDate,
          refundReasonDetail: data.refundReasonDetail,
        }
      : { refundReason: data.refundReason, issueStartDate: data.issueStartDate }

    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/payments/${data.paymentId}/refund`, data: payload },
      'refundPayment',
      data.userId,
      userStore.user?.uuid,
    )

    this.dataWait = false
    return response
  }

  public async getSubscriptions(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.subscriptions = { ...this.subscriptions, subscriptions: [], isPaymentMethodSet: false }
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription` },
      'getSubscriptions',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data) {
      this.subscriptions = response?.data
    }
    this.dataWait = false
  }

  public async cancelSubscription(data: {
    userId: string
    subscriptionId: string
    cancelImmediately: boolean
  }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()

    this.dataWait = true

    let cancelPayload = {}

    const cancellationReason = 'CX_CANCELLED' // hard-coded for now

    if (data.cancelImmediately) {
      cancelPayload = {
        cancelImmediately: data.cancelImmediately,
        cancellationReason: cancellationReason,
      }
    }

    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/cancel`,
        data: cancelPayload,
      },
      'cancelSubscription',
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_CANCEL_SUBSCRIPTION_REQUEST)
      return null
    })
    this.dataWait = false
    return response
  }

  public async extendSubscription(data: {
    userId: string
    subscriptionId: string
    escalationReason: string
    issueStartDate: string
    extensionReason: string
    extensionMonths: number
  }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.extendSubscriptionError = ''
    // Request data should be typed
    const payload = {
      extendMonths: Number(data.extensionMonths),
      extendReason: data.extensionReason,
      escalationReason: data.escalationReason,
      issueStartDate: data.issueStartDate,
    }

    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/extend-trial`,
        data: payload,
      },
      REQUEST_SOURCE_EXTEND_SUBSCRIPTION_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      const requestError = this.handleRequestError(axiosError, REQUEST_SOURCE_EXTEND_SUBSCRIPTION_REQUEST)
      if (requestError.userMessage) {
        this.extendSubscriptionError = requestError.userMessage
      }
    })

    this.dataWait = false
    await this.getSubscriptions({ userId: data.userId })
  }

  public async compensateSubscription(data: any) {
    const userStore = new UserStore()

    this.dataWait = true
    const payload: CompensateSubscriptionBaseRequest | CompensateSubscriptionWithEscalationReasonRequest = {
      compensationMonths: Number(data.compensationMonths),
      reason: data.compensationReason,
      issueStartDate: data.issueStartDate,
    }

    if (data.compensationReason === 'Escalations') {
      const escPayload = payload as CompensateSubscriptionWithEscalationReasonRequest

      escPayload['escalationReason'] = data.compensationReason
    }

    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/compensate`,
        data: payload,
      },
      'compensateSubscription',
      data.userId,
      userStore.user?.uuid,
    )

    this.dataWait = false

    await this.getSubscriptions(data)
    return response
  }

  public async upgradeSubscription(data: UpgradeSubscriptionActionData): Promise<AxiosResponse | null> {
    this.updateDataWait({ source: REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION, wait: true })
    const userStore = new UserStore()

    // Upgrade lifetime request doesn't require shipping details - in that case body is empty
    const requestData: any = {}
    if (data.shippingDetails) {
      requestData['shipToContact'] = data.shippingDetails
    }

    const upgradeSubscriptionUrlParams: SubscriptionActionParams = { validateAddress: null }
    if (data.validateAddress) {
      upgradeSubscriptionUrlParams.validateAddress = true
    }

    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/upgrade-lifetime`,
        params: upgradeSubscriptionUrlParams,
        data: requestData,
      },
      REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_REQUEST)
    })
    await this.getSubscriptions(data)
    this.updateDataWait({ source: REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION, wait: false })

    if (!response) {
      return null
    }
    return response
  }

  public async getSubscriptionAuditLogs(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    if (data.initial) {
      this.dataWait = true
      this.subscriptionAuditLogs = []
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription/audit-events` },
      'getSubscriptionAuditLogs',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.subscriptionAuditLogs = response?.data || []
    if (data.initial) {
      this.dataWait = false
    }
  }

  public async getSubscriptionHistoryEvents(data: { userId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.subscriptionHistoryEvents = []

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription/history-events` },
      'getSubscriptionHistoryEvents',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.subscriptionHistoryEvents = response?.data?.historyEvents || []
    this.dataWait = false
  }

  public async getInvoices(data: { userId: string }) {
    const userStore = new UserStore()

    this.invoices = []
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/invoices` },
      'getInvoices',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.invoices = response?.data?.invoices || []
    this.dataWait = false
  }

  public async getInvoice(data: { userId: string; invoiceId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.invoice = null

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/invoices/${data.invoiceId}`,
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      },
      'getInvoice',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.invoice = response?.data || null
    this.dataWait = false
  }

  public async getCreditMemos(data: { userId: string }) {
    const userStore = new UserStore()

    this.creditMemos = []
    this.dataWait = true

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/credit-memos`,
      },
      'getCreditMemos',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.creditMemos = response?.data?.creditMemos || []
    this.dataWait = false
  }

  public async getCreditMemo(data: { userId: string; memoId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.creditMemo = null

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/credit-memos/${data.memoId}`,
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      },
      'getCreditMemo',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.creditMemo = response?.data || null
    this.dataWait = false
  }

  public async resetSubscriptions(data: { userId: string }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/subscription/reset` },
      REQUEST_SOURCE_RESET_SUBSCRIPTION_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_RESET_SUBSCRIPTION_REQUEST)
    })
    if (!response) {
      return null
    }
    return response
  }

  public async resetDunning(data: { userId: string }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/subscription/reset-dunning-history` },
      REQUEST_SOURCE_RESET_DUNNING_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_RESET_DUNNING_REQUEST)
    })
    if (!response) {
      return null
    }
    return response
  }

  public async getContactDetails(data: { userId: string }) {
    const userStore = new UserStore()
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/contact-details` },
      'getContactDetails',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data) {
      this.contactDetails = extractContactDetailsFromResponse(response.data)
    }

    this.dataWait = false
  }

  public async startTrial(data: AddressData): Promise<AxiosResponse | null> {
    this.updateDataWait({ source: REQUEST_SOURCE_START_TRIAL_ACTION, wait: true })
    const userStore = new UserStore()

    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.userId}/subscription/start-trial`,
        params: { validateAddress: data.validateAddress },
        data: { shipToContact: data.shippingDetails },
      },
      REQUEST_SOURCE_START_TRIAL_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_START_TRIAL_REQUEST)
    })
    await this.getSubscriptions({ initial: false, userId: data.userId })
    this.updateDataWait({ source: REQUEST_SOURCE_START_TRIAL_ACTION, wait: false })
    if (!response) {
      return null
    }
    return response
  }

  public async updateAddress(data: AddressData, type: ContactType): Promise<AxiosResponse | null> {
    const requestData =
      type === 'shipToContact' ? { shipToContact: data.shippingDetails } : { billToContact: data.shippingDetails }

    this.updateDataWait({ source: REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST, wait: true })
    const userStore = new UserStore()
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/contact-details`,
        params: { validateAddress: data.validateAddress },
        data: requestData,
      },
      REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST)
    })

    this.updateDataWait({ source: REQUEST_SOURCE_UPDATE_ADDRESS_REQUEST, wait: false })

    if (!response) {
      return null
    }
    return response
  }
}
