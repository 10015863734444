import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { reactive } from 'vue'

import { getActivePinia } from 'pinia'
import { Pinia } from 'pinia-class-component'

import * as Sentry from '@sentry/browser'

import { ErrorType, RequestError, handleRequestError } from '#utils/request/requestError'
import { waitingForData } from '#utils/store/dataWait'
import { cancelRequestsByRequestSource, removeAbortController, updateAbortControllers } from '#utils/store/request'

import { DataWait, DataWaits, Nullable, RequestErrors } from '#types'

export class BaseStore extends Pinia {
  protected resetAllowed = true

  protected abortControllers = {}

  protected dataWaits: DataWaits = reactive({})

  protected requestErrors: RequestErrors = reactive({})

  public constructor() {
    super()

    // https://ponyfoo.com/articles/binding-methods-to-class-instance-objects
    this.makeRequest = this.makeRequest.bind(this)
    this.waitingForData = this.waitingForData.bind(this)
    this.updateDataWait = this.updateDataWait.bind(this)
    this.getRequestError = this.getRequestError.bind(this)
    this.handleRequestError = this.handleRequestError.bind(this)
  }

  /**
   * This is a wrapper-like helper function to make requests from Store with Axios.
   *
   * In addition to making request via Axios, this function handles user ID check, maintains abort controllers
   * and updates data wait state.
   *
   * Previous in-flight requests with same requestSource within the store will be aborted, so you should make sure
   * each store action has unique requestSource
   *
   * Improvement ideas:
   * - Some store actions make lots of data processing so endDataWait is needed. We should figure out more elegant
   *   way to handle this. Now separate markRequestCompleted() call is needed.
   *
   * @param axiosRequestConfig  Standard AxiosRequestConfig
   * @param requestSource       Request source identifier.
   * @param memberId            Member ID associated with the store action
   * @param storeMemberId       Member ID read from the global user-store (currently active member)
   * @param endDataWait         Update data wait status after we get response
   */
  public async makeRequest(
    axiosRequestConfig: AxiosRequestConfig,
    requestSource: string,
    memberId: string | null = null,
    storeMemberId: string | null = null,
    endDataWait: boolean = true,
  ): Promise<AxiosResponse | null> {
    if (memberId && storeMemberId && memberId !== storeMemberId) {
      const error = new Error(`Member IDs do not match. memberId:, ${memberId}, storeMemberId:, ${storeMemberId}`)
      Sentry.captureException(error)
      console.error(error)
      return null
    }

    axiosRequestConfig.apiEnv = sessionStorage.OuraCloudEnv

    const droppedPermissions = (sessionStorage.OuraOverrides || '').split(',')

    const sensitiveDataVisible = sessionStorage.OuraSensitiveDataVisible === 'true'

    if (!sensitiveDataVisible && !droppedPermissions.includes('allowSensitiveDataAccess')) {
      axiosRequestConfig.dropPermissions = ['allowSensitiveDataAccess']
    }

    this.updateDataWait({ source: requestSource, wait: true })

    cancelRequestsByRequestSource(this.abortControllers, requestSource)

    const controller = new AbortController()
    // We generate random string as requestId - https://stackoverflow.com/a/19964557
    const abortControllerContainer = {
      requestSource: requestSource,
      controller: controller,
      requestId: (Math.random().toString(36) + '00000000000000000').slice(2, 16),
    }
    updateAbortControllers(this.abortControllers, abortControllerContainer)
    axiosRequestConfig['signal'] = controller.signal

    const response = await getActivePinia()!
      .$axios(axiosRequestConfig)
      .catch((axiosError: AxiosError) => {
        removeAbortController(this.abortControllers, abortControllerContainer)
        if (endDataWait) {
          this.updateDataWait({ source: requestSource, wait: false })
        }
        throw axiosError
      })

    if (endDataWait) {
      this.updateDataWait({ source: requestSource, wait: false })
    }

    if (!controller.signal.aborted) {
      // If request was successful we can remove its AbortController
      removeAbortController(this.abortControllers, abortControllerContainer)
      return response
    }
    // If request was cancelled: remove abort controller and throw exception
    removeAbortController(this.abortControllers, abortControllerContainer)
    throw response
  }

  public getRequestError(requestSource: string): Nullable<RequestError> {
    return this.requestErrors[requestSource]
  }

  public handleRequestError(axiosError: AxiosError, requestSource: string): RequestError {
    const requestError = handleRequestError(axiosError)

    this.requestErrors[requestSource] = requestError

    if (requestError.type == ErrorType.UNEXPECTED_CLIENT_ERROR || requestError.type == ErrorType.UNEXPECTED_ERROR) {
      Sentry.captureException(requestError)
    }
    return requestError
  }

  public waitingForData(requestSources: string[] = []) {
    return waitingForData(this.dataWaits, requestSources)
  }

  public updateDataWait(wait: DataWait): void {
    this.dataWaits[wait.source] = wait.wait
  }
}
