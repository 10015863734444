<template>
  <OuraHeader
    :texts="[
      'Deep dive to user`s journey with Oura',
      '..if you have the permissions to do so...',
      'Darwin - home of the diagnostics',
    ]"
  />

  <div class="page">
    <div class="text-primary text-h5">Welcome to the Oura users journal</div>

    <div class="px-4 py-8">
      <div class="text-body-1">
        Darwin's end goal is to make troubleshooting problems faster by visualizing the users whole journey with Oura.
        <br />
        <br />
        <div class="text-h6">Access rights</div>
        <br />
        Everyone has access to the technical journal entries, for more sensitive ones you need to request access from IT
        service portal.
        <br />
        <br />
        Here are steps how to do it:
        <br />
        <br />
        First make sure you have gone through the
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSc3CjZKLNwc2ac-VG06tKaW_7cF6Rap6BbQdyv-l8eU2oYTkg/viewform?usp=sf_link"
          target="_blank"
        >
          Oura's Data Protection and Security training
        </a>
        .
        <br />
        Please communicate with your manager about the needed
        <a
          href="https://ouraring.atlassian.net/wiki/spaces/SQUAD/pages/2589130831/Oura+user+data+rights+roles"
          target="_blank"
        >
          access levels
        </a>
        for your role.

        <br />

        Request access from Oura IT portal -
        <a href="http://oura.service-now.com/sp" class="text-info text-no-wrap text-decoration-none" target="_blank">
          Service now
        </a>
        .
        <br />

        <br />
        <br />
        <div class="title">Respect privacy of our users</div>
        <br />
        Remember to follow our guidelines / values and most importantly respect our users and other employees privacy.
        <br />
        <b>
          You may access user's data only when the user has requested help, or has given specific consent to access
          her/his data.
        </b>
      </div>
    </div>

    <v-spacer class="pa-3" />

    <div class="text-primary text-h5">Whats new in Darwin</div>

    <div ref="latest" class="px-2">
      <Changelog class="changelog" style="overflow: hidden" :style="{ 'max-height': changelogHeight + 'px' }" />
    </div>

    <div class="mb-4 px-2" style="overflow: hidden">
      <v-expand-transition>
        <Changelog v-show="fullChangelog" class="changelog" :style="{ 'margin-top': '-' + changelogHeight + 'px' }" />
      </v-expand-transition>
    </div>

    <span v-if="!fullChangelog" class="caption mt-n4 px-2" @click="fullChangelog = true">Show older releases</span>
    <span v-else class="caption px-2" @click="fullChangelog = false">Hide older releases</span>
  </div>
</template>

<script lang="ts">
  // @ts-expect-error - Markdown files are not supported by typescript
  import Changelog from '../CHANGELOG.md'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { OuraHeader } from '@jouzen/outo-apps-toolkit'

  @Component({
    components: {
      Changelog,
      OuraHeader,
    },
  })
  class Home extends Vue {
    public changelogHeight = 0

    public fullChangelog = false

    public mounted() {
      const latest = this.$refs.latest as HTMLElement
      const element = latest.querySelectorAll('h1')[1]

      if (element) {
        this.changelogHeight = element.getBoundingClientRect().y - latest.getBoundingClientRect().y
      }
    }
  }

  export default toNative(Home)
</script>

<style lang="scss" scoped>
  @use 'vuetify/settings' as v;

  .page {
    position: relative;
    margin-top: -50px;
    z-index: 1;
    background: rgb(var(--v-theme-background));
    padding: 50px 96px;
  }

  :deep(.changelog) {
    h1 {
      a {
        color: rgb(var(--v-theme-foreground));
        font-weight: bold;
        text-decoration: unset;
      }
    }

    h2 {
      color: rgb(var(--v-theme-foreground));
      font-weight: normal;
      padding: 8px 0 0 0;
    }
  }

  @media #{map-get(v.$display-breakpoints, 'sm-and-down')} {
    .page {
      padding: 40px;
    }
  }
</style>
