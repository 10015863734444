import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { Nullable } from '#types'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)

/**
 * Formats datetime string to custom format. Returns null if input is invalid, otherwise formatted string.
 *
 * Parameter date can be anything that dayjs accepts. If timezone is not set, we expect it to be UTC.
 *
 * Gotchas / good to know:
 * - If you provide just date (2024-05-15) it's time will be 00:00 in UTC. So if you also provide negative targetTimezone,
 * the outputted date will be 2024-05-14.
 *
 * Parameter format accepts any Day.js format (https://day.js.org/docs/en/display/format)
 * Parameter targetTimezone is minutes from UTC. For example 180 is '+03:00', -480 is '-08:00' and null/0 is UTC.
 *
 * @param date            Input timestamp
 * @param format          Dayjs format
 * @param targetTimezone  Target timezone in minutes from UTC
 */
export function formatDateTime(
  date: Nullable<string | number | Date | Dayjs | null | undefined>,
  format: string,
  targetTimezone?: Nullable<number>,
): Nullable<string> {
  if (!date) {
    return null
  }
  const dateParsed: dayjs.Dayjs = dayjs.utc(date)
  if (targetTimezone) {
    return dateParsed.add(targetTimezone, 'minute').format(format).toString()
  }
  return dateParsed.format(format).toString()
}

/**
 * Get timezone UTC offset
 *
 * Output is always in format hours:minutes from UTC with + or - sign on front.
 * Example return values: +00:00, +03:00, -07:00, +05:45
 *
 * @param timezoneMinutes Timezone offset, minutes from UTC
 * @return                Timezone offset, hours:minutes from UTC
 */
export function getTimezoneUTCOffset(timezoneMinutes: number): string {
  const prefix = timezoneMinutes >= 0 ? '+' : '-'
  return prefix + dayjs.utc(Math.abs(timezoneMinutes) * 60 * 1000).format('HH:mm')
}
