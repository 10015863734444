import dayjs from 'dayjs'

import { WarrantyStateAndEffectiveEndDate } from '#types'

export enum WarrantyState {
  InWarranty = 'In warranty',
  OutOfWarranty = 'Out of warranty',
  Unknown = 'unknown',
}

export function getEffectiveEndDate(warrantyEndDate: string, escEndDate?: string | null): string {
  if (escEndDate && new Date(escEndDate) > new Date(warrantyEndDate)) {
    return escEndDate
  } else {
    return warrantyEndDate
  }
}

export function getWarrantyInfo(
  warrantyStartDate?: string | null,
  warrantyEndDate?: string | null,
  escEndDate?: string | null,
): WarrantyStateAndEffectiveEndDate {
  const effectiveEndDate = getEffectiveEndDate(warrantyEndDate!, escEndDate)
  const state = getState(warrantyStartDate, effectiveEndDate)

  const warrantyState = getWarrantyState(state!)

  return { state, warrantyState, effectiveEndDate }
}

export function getWarrantyState(state: WarrantyState): WarrantyStateAndEffectiveEndDate['warrantyState'] {
  switch (state) {
    case WarrantyState.InWarranty:
      return 'success'
    case WarrantyState.OutOfWarranty:
      return 'error'
    default:
      return 'warning'
  }
}

export function getState(warrantyStartDate: string | undefined | null, effectiveEndDate: string) {
  let state
  if (warrantyStartDate && effectiveEndDate) {
    const startTimestamp = new Date(warrantyStartDate).getTime()
    const endTimestamp = new Date(effectiveEndDate).getTime()
    const currentTime = Date.now()

    if (
      currentTime > startTimestamp &&
      (dayjs(effectiveEndDate).isSame(dayjs(), 'day') || currentTime <= endTimestamp)
    ) {
      state = WarrantyState.InWarranty
    } else if (currentTime > endTimestamp) {
      state = WarrantyState.OutOfWarranty
    }
  } else {
    state = WarrantyState.Unknown
  }
  return state
}
