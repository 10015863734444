<template>
  <NoRingBanner v-if="!user.rings.length" />

  <LastSyncInfo v-else-if="ring" :ring="ring" />

  <OverviewInfo v-if="user" v-model:ring="ring" :user="user" :rings="user.rings" class="mt-8" />

  <DiagnosticsResult class="mt-8" :user="user" :ring="ring" :sidecar="false" />

  <template v-if="user">
    <div v-if="$featureEnabled('summaryV2Component')" class="mt-8 mb-n10">
      <v-row class="pr-8 flex d-flex flex-row-reverse">
        <v-switch v-model="showSummaryV2" label="Use Summary Data Report V2" class="reversed" />
      </v-row>
    </div>

    <SummaryReport v-if="showSummaryV2 && $featureEnabled('summaryV2Component')" class="mt-8" :user-uuid="user.uuid" />

    <SummaryReportV1 v-else :uuid="user.uuid" :ring="ring" :rings="user.rings" :summary-fields="summaryFields" />
  </template>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { summaryFieldsDashboardv2 } from '#views/members/constants'

  import { DiagnosticsStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class AccountOverview extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring

    public showSummaryV2 = true

    public diagnosticsStore = new DiagnosticsStore()

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Dashboard',
        page_location: window.location.toString().split('?')[0],
      })
    }

    /**
     * Which summary data report fields to show
     */
    public get summaryFields(): string[] {
      return summaryFieldsDashboardv2
    }
  }

  export default toNative(AccountOverview)
</script>

<style lang="scss" scoped>
  :deep(.legacy) {
    .v-select__selection {
      color: red !important;
    }
  }
  :deep(.v-banner__content) {
    font-size: large;
  }
</style>
