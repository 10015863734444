<template>
  <div>
    <v-card :loading="dataWait">
      <v-card-title>
        <p class="text-overline">Payment methods</p>

        <v-spacer />

        <v-btn v-if="canSeeContacts" class="mt-n1 mr-n2" color="blue" variant="text" @click="toggleContacts()">
          {{ showContacts ? 'Hide' : 'Show' }} contact info
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row v-if="showContacts && canSeeContacts">
          <v-col v-for="contactType in ['billToContact', 'shipToContact']" :key="contactType" cols="6">
            <ContactDetails :contact-type="contactType" :contact-details="contactDetails[contactType]" :user="user" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-alert v-if="!!error" tile class="px-5 v-sheet--tile" type="error" color="red">
        <v-row class="px-3">
          {{ error }}
        </v-row>
      </v-alert>
      <v-data-table
        loading-text="Loading payment methods..."
        :headers="sidecar ? sidecarHeaders : headers"
        :items="paymentMethods"
        :items-per-page="10"
        no-data-text="No payment methods data available"
        :sort-by="[{ key: 'expirationYear', order: 'desc' }]"
        item-key="date"
        :hide-default-footer="sidecar ? true : false"
        class="elevation-1"
      >
        <template #item.isDefault="{ item }">
          <span>{{ item.isDefault ? 'Yes' : 'No' }}</span>
        </template>
        <template #item.email="{ item }">
          <span>{{ canSeeContacts ? item.email : 'Hidden' }}</span>
        </template>
        <template #item.createdAt="{ item }">
          <span>{{ formatDateTime(item.createdAt, 'DD MMM YYYY') }}</span>
        </template>
        <template #item.status="{ item }">
          <span :class="getClass(item.status)">{{ item.status }}</span>
          <span v-if="sidecar">{{ item.isDefault ? ' (is default)' : ' (not default)' }}</span>
        </template>
        <template v-if="sidecar" #item.type="{ item }">
          <span>{{ item.type }}</span>
          <span v-if="item.cardType">&nbsp;({{ item.cardType }})</span>
        </template>
        <template v-if="sidecar" #item.expirationYear="{ item }">
          <span>{{ item.expirationYear }}/{{ String(item.expirationMonth).padStart(2, '0') }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn icon class="px-2" v-bind="props">
                <v-icon :disabled="!canDeletePaymentMethod" color="darkgrey" @click="showConfirmDialog(item.id)">
                  mdi-trash-can
                </v-icon>
              </v-btn>
            </template>
            <span>Remove payment method</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="confirmDialogVisible" width="500">
      <v-card>
        <v-card-title class="headline">Confirm deletion</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span>Are you sure you want to remove this payment method?</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="confirmDialogVisible = false">Cancel</v-btn>
          <v-btn variant="text" color="primary" @click="removePaymentMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { paymentMethodsHeaders, paymentMethodsHeadersSidecar } from '#views/members/constants'

  import { AppStore, SubscriptionStore } from '#stores'

  import { PaymentMethodCreditCard } from '#types'

  @Component
  class PaymentMethods extends mixins(DateTime) {
    @Prop() public user!: any
    @Prop() public sidecar!: boolean

    public subscriptionStore = new SubscriptionStore()
    public appStore = new AppStore()

    public headers = paymentMethodsHeaders
    public sidecarHeaders = paymentMethodsHeadersSidecar
    public paymentMethodId = ''
    public confirmDialogVisible = false
    public error = ''
    public showContacts = false

    public get dataWait(): boolean {
      return this.subscriptionStore.dataWait
    }

    public get rights() {
      return this.appStore.rights || []
    }

    public get canDeletePaymentMethod(): boolean {
      return this.rights.includes('rolesPaymentAdmin')
    }

    public get canSeeContacts(): boolean {
      return (
        (this.rights.includes('rolesPaymentAdmin') || this.rights.includes('rolesPaymentViewer')) &&
        this.rights.includes('allowPersonalDataAccess')
      )
    }

    public get paymentMethods() {
      return this.subscriptionStore.paymentMethods as PaymentMethodCreditCard[]
    }

    public get contactDetails() {
      return this.subscriptionStore.contactDetails as any
    }

    public mounted() {
      if (this.user) {
        this.initializePaymentMethods()
      }
    }

    private initializePaymentMethods() {
      this.subscriptionStore.getPaymentMethods({ userId: this.user?.uuid })
    }

    public getClass(status: string): string {
      if (status === 'Active') {
        return 'text-green'
      } else if (status === 'Closed') {
        return 'text-red'
      } else {
        return 'text-grey'
      }
    }

    public showConfirmDialog(paymentMethodId: string) {
      this.confirmDialogVisible = true
      this.paymentMethodId = paymentMethodId
    }

    public toggleContacts() {
      this.showContacts = !this.showContacts
      if (this.showContacts) {
        this.subscriptionStore.getContactDetails({ userId: this.user?.uuid })
      }
    }

    public removePaymentMethod() {
      if (!this.canDeletePaymentMethod) {
        this.confirmDialogVisible = false
        return
      }
      const payload = {
        userId: this.user.uuid,
        paymentMethodId: this.paymentMethodId,
      }
      this.subscriptionStore.removePaymentMethod(payload).then((res) => {
        if (res !== 204) {
          this.error = 'Unable to remove payment method'
        }
        this.confirmDialogVisible = false
      })
    }
  }

  export default toNative(PaymentMethods)
</script>
<style lang="css" scoped>
  :deep(.v-data-table-header.v-data-table-header-mobile) {
    display: none;
  }
</style>
