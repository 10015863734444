import { AbortControllerContainer } from '#types'

export function updateAbortControllers(abortControllers: any, abortControllerContainer: AbortControllerContainer) {
  const source = abortControllerContainer['requestSource']

  if (!abortControllers[source]) {
    abortControllers[source] = []
  }
  abortControllers[source].push(abortControllerContainer)
}

export function removeAbortController(abortControllers: any, abortControllerContainer: AbortControllerContainer) {
  const requestSource = abortControllerContainer['requestSource']
  if (!abortControllers[requestSource]) {
    return
  }
  for (let i = 0; i < abortControllers[requestSource].length; i++) {
    if (abortControllers[requestSource][i].requestId === abortControllerContainer.requestId) {
      abortControllers[requestSource].splice(i, 1)
    }
  }
}

/**
 * Cancels all non-aborted requests related to requestSource, and cleans up abortControllers list.
 */
export function cancelRequestsByRequestSource(abortControllers: any, requestSource: string) {
  if (abortControllers[requestSource]?.length > 0) {
    for (let i = 0; i < abortControllers[requestSource].length; i++) {
      const container = abortControllers[requestSource][i]
      if (!container.controller.signal.aborted) {
        // We get here for example if we have request which takes a long time to complete (so it's not yet aborted), and
        // application's refresh logic wants to get the same data again. Here we see that there is previous in-flight
        // request, so we cancel it.
        console.debug('Started new request. Cancelled the previous one. requestSource:', requestSource)
        container.controller.abort('Started new request. Cancelled the previous one')
      }
      // Remove AbortController from list, so we don't check it again.
      abortControllers[requestSource].splice(i, 1)
    }
  }
}
