<template>
  <v-dialog
    v-model="isOpen"
    width="500"
    class="backdrop"
    @click:outside="closeDialog('click-outside')"
    @keydown="closeDialog($event.keyCode)"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2 pb-n4 mb-n4" primary-title>{{ headerText }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular
            v-show="dataWait"
            indeterminate
            size="70"
            width="8"
            style="position: absolute; top: 50%; left: 45%"
          />
          <v-row ref="notification">
            <div style="position: sticky; top: 60px; width: 100%">
              <v-sheet min-height="50">
                <v-alert v-if="notificationText || isGeneralError" :type="notificationType">
                  <div v-if="isGeneralError">
                    <p v-for="err in shippingDetailsErrors.generalError" :key="err">
                      {{ err }}
                    </p>
                    <p>
                      If you are sure the address is correct anyway, select "Bypass address validation" and try again.
                    </p>
                  </div>
                  <div v-else>
                    {{ notificationText }}
                  </div>
                </v-alert>
              </v-sheet>
            </div>
          </v-row>
          <v-row>
            <slot name="content">
              <v-col>
                <p>{{ contentText }}</p>
              </v-col>
            </slot>
          </v-row>
          <section class="form">
            <div class="field">
              <v-text-field
                v-model="shippingDetails.firstName"
                persistent-hint
                :hint="disabledInputs.firstName"
                :disabled="!inputEnabled('firstName')"
                label="First name"
                :error-messages="shippingDetailsErrors.firstName"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.lastName"
                persistent-hint
                :hint="disabledInputs.lastName"
                :disabled="!inputEnabled('lastName')"
                label="Last name"
                :error-messages="shippingDetailsErrors.lastName"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.personalEmail"
                persistent-hint
                :hint="disabledInputs.personalEmail"
                :disabled="!inputEnabled('personalEmail')"
                label="Email"
                :error-messages="shippingDetailsErrors.personalEmail"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.address1"
                persistent-hint
                :hint="disabledInputs.address1"
                :disabled="!inputEnabled('address1')"
                label="Address"
                :error-messages="shippingDetailsErrors.address1"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.address2"
                persistent-hint
                :hint="disabledInputs.address2"
                :disabled="!inputEnabled('address2')"
                label="Address 2"
                :error-messages="shippingDetailsErrors.address2"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.city"
                persistent-hint
                :hint="disabledInputs.city"
                :disabled="!inputEnabled('city')"
                label="City"
                :error-messages="shippingDetailsErrors.city"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-autocomplete
                v-model="shippingDetails.country"
                persistent-hint
                :hint="disabledInputs.country"
                :disabled="!inputEnabled('country')"
                :items="countriesAndCodes"
                label="Country"
                class="mt-2"
                :error-messages="shippingDetailsErrors.country"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              >
                <template #append>
                  <v-slide-x-reverse-transition mode="out-in" />
                </template>
              </v-autocomplete>
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.state"
                persistent-hint
                :hint="disabledInputs.state"
                :disabled="!inputEnabled('state')"
                label="State"
                :error-messages="shippingDetailsErrors.state"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-text-field
                v-model="shippingDetails.postalCode"
                persistent-hint
                :hint="disabledInputs.postalCode"
                :disabled="!inputEnabled('postalCode')"
                label="Postal code"
                :error-messages="shippingDetailsErrors.postalCode"
                hide-details="auto"
                @keyup="validateShippingDetailsSingle()"
              />
            </div>
            <div class="field">
              <v-checkbox
                v-model="validationWithoutAddress"
                :disabled="dataWait || justSaved"
                label="Bypass address validation"
              />
            </div>
          </section>
        </v-container>
      </v-card-text>

      <v-divider />

      <slot name="buttons">
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn variant="text" :disabled="dataWait" @click="close()">{{ closeButtonText }}</v-btn>
          <v-btn v-if="showSaveButton" :disabled="dataWait" variant="text" color="primary" @click="saveDetails()">
            Save and confirm
          </v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { getCountryNameAndCodeList } from '#utils/country/list'
  import { extractShippingDetailsErrorsFromFormValidationProblems } from '#utils/subscription/extract.shippingDetails'
  import {
    UserShippingDetailsValidator,
    shippingDetailsEmpty,
    shippingDetailsNoErrors,
  } from '#utils/user/shippingDetailsValidator'
  import { getHumanReadableAddressType } from '#utils/utils'

  import { SubscriptionStore } from '#stores'

  import {
    ContactType,
    PaymentMethodsContact,
    ShippingDetailsDialogAction,
    ShippingDetailsDisabledInputs,
    ShippingDetailsErrors,
    ShippingDetailsInfo,
    SubscriptionInfo,
  } from '#types'
  import { Member } from '#types'

  @Component
  export class ShippingDetails extends Vue {
    @Prop() public member!: Member
    public action: ShippingDetailsDialogAction | null = null
    public isOpen = false
    public headerText = 'Confirm'
    public contentText = 'Are you sure?'
    public notificationText = ''
    public notificationType: 'warning' | 'error' | 'success' | 'info' = 'info'
    public subscriptionStore = new SubscriptionStore()
    public shippingDetails: ShippingDetailsInfo = shippingDetailsEmpty()
    public shippingDetailsErrors: ShippingDetailsErrors = shippingDetailsNoErrors()
    public shippingDetailsValidator: UserShippingDetailsValidator | null = null
    public subscription: SubscriptionInfo | null = null
    public contactDetails: PaymentMethodsContact | null = null
    public justSaved = false
    public validationWithoutAddress = false
    public disabledInputs: ShippingDetailsDisabledInputs = {}

    public declare $refs: {
      notification: any
    }

    public get countriesAndCodes() {
      return getCountryNameAndCodeList()
    }

    public get showSaveButton(): boolean {
      return !this.justSaved
    }

    public get closeButtonText(): string {
      return this.justSaved ? 'Close' : 'Cancel'
    }

    public get isGeneralError(): boolean {
      return !!this.shippingDetailsErrors.generalError && !!this.shippingDetailsErrors.generalError.length
    }

    public inputEnabled(fieldName: keyof ShippingDetailsInfo): boolean {
      if (this.justSaved || this.dataWait || this.disabledInputs[fieldName]) {
        return false
      }
      return true
    }

    /***
     * Open the confirm dialog with given header and text
     * @param action
     * @param headerText    Dialogs header text
     * @param contextText   Main text of the dialog
     * @param subscription
     */
    public async open(
      action: ShippingDetailsDialogAction,
      headerText: string,
      contextText: string,
      subscription: SubscriptionInfo | null = null,
      contactDetails: PaymentMethodsContact | null = null,
      disabledInputs: ShippingDetailsDisabledInputs = {},
    ) {
      this.action = action
      this.headerText = headerText
      this.contentText = contextText
      this.subscription = subscription
      this.disabledInputs = disabledInputs
      if (contactDetails) {
        this.shippingDetails = { ...contactDetails } as any
      }

      if (!this.shippingDetailsValidator) {
        this.shippingDetailsValidator = new UserShippingDetailsValidator()
      }

      const shouldResetShippingDetails =
        this.action !== 'updateBillingAddress' && this.action !== 'updateShippingAddress'

      this.reset(shouldResetShippingDetails)

      this.isOpen = true
    }

    public get dataWait(): boolean {
      switch (this.action) {
        case 'startTrial':
          return this.subscriptionStore.waitingForStartTrialData()
        case 'upgradeLifetime':
          return this.subscriptionStore.waitingForUpgradeLifetimeData()
        case 'updateBillingAddress':
          return this.subscriptionStore.waitingForAddressUpdate()
        case 'updateShippingAddress':
          return this.subscriptionStore.waitingForAddressUpdate()
      }
      return false
    }

    public async close() {
      this.isOpen = false
      this.notificationType = 'info'
      this.notificationText = ''
      this.justSaved = false
      this.validationWithoutAddress = false
    }

    public reset(resetShippingDetails: boolean) {
      if (resetShippingDetails) {
        this.shippingDetails = shippingDetailsEmpty()
      }
      this.shippingDetailsErrors = shippingDetailsNoErrors()
      this.notificationType = 'info'
      this.notificationText = ''
      this.justSaved = false
      this.validationWithoutAddress = false
    }

    @Debounce(300)
    public validateShippingDetailsSingle(): boolean {
      // This prevents showing "Cannot be empty" messages on all fields when user is just
      // starting to fill the information
      return this.validateShippingDetails(['Cannot be empty'])
    }

    public validateShippingDetails(suppressMessages: string[] = []): boolean {
      if (!this.shippingDetailsValidator) {
        this.shippingDetailsValidator = new UserShippingDetailsValidator()
      }

      const { errors, allValid, cleanShippingDetails } = this.shippingDetailsValidator.sanitizeAndValidate(
        this.shippingDetails,
        suppressMessages,
      )
      this.shippingDetailsErrors = errors
      this.shippingDetails = cleanShippingDetails

      return allValid
    }

    private get saveOk(): boolean {
      return this.shippingDetails && this.validateShippingDetails()
    }

    public async saveDetailsStartTrial() {
      this.notificationType = 'info'
      this.notificationText = 'Starting trial.'

      logEvent(this.$analytics, 'subscription_trial', {
        category: 'Subscription:trial',
        action: 'Click trial subscription',
        label: 'Click trial subscription',
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      /**
       * in the backend, we send validateAddress as params
       * therefore, this case, it is opposite logic with the frontend checkbox.
       *
       * */

      const response = await this.subscriptionStore.startTrial({
        userId: this.member.uuid,
        shippingDetails: this.shippingDetails,
        validateAddress: !this.validationWithoutAddress,
      })
      if (response) {
        this.justSaved = true
        this.notificationType = 'success'
        this.notificationText = 'Trial started.'
      } else if (this.subscriptionStore.getRequestError('startTrial')) {
        const requestError = this.subscriptionStore.getRequestError('startTrial')!
        this.shippingDetailsErrors = extractShippingDetailsErrorsFromFormValidationProblems(
          requestError.formValidationProblems,
        )
        if (requestError.userMessage) {
          this.notificationType = 'error'
          this.notificationText = requestError.userMessage
        }
      }
    }
    public async saveDetailsUpdateAddress(contactType: ContactType) {
      const addressType = getHumanReadableAddressType(contactType)

      if (!this.shippingDetails) {
        throw new Error(`Trying to update ${addressType} address, but data is missing.`)
      }

      this.notificationText = `Updating member's ${addressType} address`
      this.notificationType = 'info'

      logEvent(this.$analytics, `update_${addressType}_address`, {
        category: `Subscription:update ${addressType} address`,
        action: `Click update ${addressType} address`,
        label: `Click update ${addressType} address`,
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      const response = await this.subscriptionStore.updateAddress(
        {
          userId: this.member.uuid,
          shippingDetails: this.shippingDetails,
          validateAddress: !this.validationWithoutAddress,
        },
        contactType,
      )
      if (response) {
        this.justSaved = true
        this.notificationType = 'success'
        this.notificationText = `Updated members ${addressType} address.`
        await this.subscriptionStore.getContactDetails({ userId: this.member.uuid })
      } else if (this.subscriptionStore.getRequestError('updateAddress')) {
        const requestError = this.subscriptionStore.getRequestError('updateAddress')!
        this.shippingDetailsErrors = extractShippingDetailsErrorsFromFormValidationProblems(
          requestError.formValidationProblems,
        )
        if (requestError.userMessage) {
          this.notificationType = 'error'
          this.notificationText = requestError.userMessage
        }
      }
    }

    public async saveDetailsUpgradeLifetime() {
      if (!this.subscription) {
        throw new Error('Trying to upgrade subscription to lifetime, but subscription data is missing.')
      }
      this.notificationType = 'info'
      this.notificationText = 'Upgrading member account to lifetime.'

      logEvent(this.$analytics, 'subscription_lifetime', {
        category: 'Subscription:lifetime',
        action: 'Click lifetime subscription',
        label: 'Click lifetime subscription',
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })

      const response = await this.subscriptionStore.upgradeSubscription({
        userId: this.member.uuid,
        subscriptionId: this.subscription.id,
        shippingDetails: this.shippingDetails,
        validateAddress: !this.validationWithoutAddress,
      })

      if (response) {
        this.justSaved = true
        this.notificationType = 'success'
        this.notificationText = 'Upgraded member account subscription to lifetime.'
      } else if (this.subscriptionStore.getRequestError('upgradeSubscription')) {
        const requestError = this.subscriptionStore.getRequestError('upgradeSubscription')!
        this.shippingDetailsErrors = extractShippingDetailsErrorsFromFormValidationProblems(
          requestError.formValidationProblems,
        )
        if (requestError.userMessage) {
          this.notificationType = 'error'
          this.notificationText = requestError.userMessage
        }
      }
    }

    public async saveDetails() {
      if (this.saveOk) {
        switch (this.action) {
          case 'startTrial':
            await this.saveDetailsStartTrial()
            break
          case 'upgradeLifetime':
            await this.saveDetailsUpgradeLifetime()
            break
          case 'updateBillingAddress':
            await this.saveDetailsUpdateAddress('billToContact')
            break
          case 'updateShippingAddress':
            await this.saveDetailsUpdateAddress('shipToContact')
            break
        }
      }

      this.scrollToNotification()
    }

    /***
     * Handle events from clicking outside v-dialog
     * or closing the dialog by pressing ESC
     * @param event
     */
    public closeDialog(event: string | number) {
      if (event == 'click-outside' || event == 27) {
        this.close()
      }
    }

    private scrollToNotification() {
      this.$refs.notification.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  export default toNative(ShippingDetails)
</script>

<style lang="scss" scoped>
  .v-input--error {
    color: red;
  }
</style>
