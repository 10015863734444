<template>
  <div v-if="!arrythmiaToolPermission">
    <v-alert colored-border type="info" class="mb-8">
      <p>You do not have access rights to view sensitive health data.</p>
    </v-alert>
  </div>
  <div v-else>
    <v-card :loading="dataWait">
      <v-card-title>
        <div class="text-overline">Arrhythmia tool</div>
      </v-card-title>

      <v-card-text>
        <v-toolbar>
          <DatePicker v-model="toDate" label="To date" :disabled="disabled" @valid="toDateValid = $event" />

          <v-btn
            variant="elevated"
            class="ml-4"
            color="primary"
            :disabled="disabled || !toDateValid"
            @click="runArrhythmiaTool()"
          >
            Generate
          </v-btn>
        </v-toolbar>

        <v-row>
          <v-col cols="12">
            <PlotlyView :data="ibiPlotData" :full-screen-supported="true" title="IBI Data" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="(graph, index) in ppgPlotData" :key="index" cols="12">
            <PlotlyView :data="graph" :full-screen-supported="true" title="PPG Data" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { EMPTY_GRAPH } from '#utils/generic-graph/generic-graph'

  import { AppStore, ArrhythmiaStore, UserStore } from '#stores'

  @Component
  class ArrhythmiaTool extends mixins(DateTime) {
    @Prop() public user!: any

    @Prop() public disabled!: boolean

    public toDate = ''
    public toDateValid = false
    public expanded = false

    public appStore = new AppStore()
    public userStore = new UserStore()
    public arrhythmiaStore = new ArrhythmiaStore()

    public get rights() {
      return this.appStore.activeRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get dataWait() {
      return this.arrhythmiaStore.dataWait
    }

    public get ibiPlotData() {
      return this.arrhythmiaStore.ibiPlotData ? this.arrhythmiaStore.ibiPlotData : EMPTY_GRAPH
    }

    public get ppgPlotData() {
      return this.arrhythmiaStore.ppgPlotData?.length ? this.arrhythmiaStore.ppgPlotData : [EMPTY_GRAPH]
    }

    public get sensitiveDataVisible() {
      return this.userStore.sensitiveDataVisible
    }

    public get arrythmiaToolPermission() {
      return !!(this.rights.includes('allowSensitiveDataAccess') || this.access) && this.sensitiveDataVisible
    }

    public mounted() {
      this.toDate = this.$dayjs().format('YYYY-MM-DD')

      this.arrhythmiaStore.resetData()
    }

    public async runArrhythmiaTool() {
      await this.arrhythmiaStore.getIbiPlotData(this.user.uuid, this.toDate)
      await this.arrhythmiaStore.getPPGPlotData(this.user.uuid, this.toDate)
    }
  }

  export default toNative(ArrhythmiaTool)
</script>
