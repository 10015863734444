<template>
  <div>
    <v-card :loading="!user || dataWait" min-width="100%">
      <v-card-title class="d-flex" style="cursor: pointer" @click="moreInfo()">
        <p class="text-overline">Subscriptions ({{ subscriptions.subscriptions.length }})</p>
        <v-spacer />

        <v-tooltip v-if="link" location="bottom">
          <template #activator="{ props }">
            <v-btn icon="mdi-information" variant="plain" color="blue" v-bind="props" @click.stop="infoDialog = true" />
          </template>
          <span>States info</span>
        </v-tooltip>
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-btn
              v-if="$featureEnabled('resetDunningHistory') && rights.includes('allowAccountEditAccess')"
              icon="mdi-restart"
              color="blue"
              class="ml-2 mx-n2"
              :style="sidecar ? '' : 'margin-top: -5px'"
              variant="plain"
              v-bind="props"
              @click="openResetDunningHistory()"
            />
          </template>
          <span>Reset dunning history</span>
        </v-tooltip>

        <v-tooltip v-if="link" location="bottom">
          <template #activator="{ props }">
            <v-btn icon="mdi-share" variant="plain" color="blue" class="ml-2" v-bind="props" @click.stop="moreInfo()" />
          </template>
          <span>Go to Darwin</span>
        </v-tooltip>

        <v-btn v-if="!link" class="overline" color="blue" variant="text" @click.stop="infoDialog = true">INFO</v-btn>
      </v-card-title>

      <v-alert v-if="errorText" tile variant="tonal" type="error" color="red" class="px-6" style="position: relative">
        Error occured while trying to update subscription: {{ errorText }}
      </v-alert>

      <v-alert
        v-if="!dataWait && subscriptions && subscriptions.defaultPaymentMethodCardExpired === true"
        tile
        variant="tonal"
        type="info"
        color="warning"
        class="px-6"
        style="position: relative"
      >
        User's default payment card has expired!
      </v-alert>

      <v-alert
        v-if="!dataWait && subscriptions?.dunningLevel && subscriptions.dunningLevel > 0"
        tile
        variant="tonal"
        type="info"
        color="error"
        class="px-6"
        style="position: relative"
      >
        Account in dunning. Subscription can not be upgraded or extended until a valid payment is method added.
        <br />
        Dunning state: {{ subscriptions.dunningLevel }}
      </v-alert>

      <v-alert
        v-if="signupFlowDiagnosticsMessage"
        tile
        variant="tonal"
        type="info"
        color="info"
        class="px-6"
        style="position: relative"
        :density="sidecar ? 'compact' : 'default'"
      >
        {{ signupFlowDiagnosticsMessage }}
      </v-alert>

      <v-card-text v-if="subscriptions.subscriptions.length" :class="sidecar ? 'pa-0' : 'pa-4'">
        <component
          :is="sidecar ? 'VCarousel' : 'div'"
          :show-arrows="subscriptions.subscriptions.length > 1 ? 'hover' : false"
          hide-delimiters
          height="100%"
        >
          <component
            :is="sidecar ? 'VCarouselItem' : 'div'"
            v-for="(sub, index) in subscriptions.subscriptions"
            :key="sub.id"
            class="pa-4 pb-7"
          >
            <v-row class="subinfo align-center" :class="sidecar ? 'mx-n1' : ''">
              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field
                  label="START DATE"
                  readonly
                  :hide-details="sidecar"
                  :model-value="formatDateTime(sub.startDate, 'DD MMM YYYY')"
                />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field
                  label="END DATE"
                  readonly
                  :hide-details="sidecar"
                  :model-value="sub.endDate ? formatDateTime(sub.endDate, 'DD MMM YYYY') : 'No data'"
                />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field
                  label="NEXT BILLING DATE"
                  readonly
                  :hide-details="sidecar"
                  :model-value="sub.nextBillingDate ? formatDateTime(sub.nextBillingDate, 'DD MMM YYYY') : 'No data'"
                />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field
                  label="NEXT PAYMENT DATE"
                  readonly
                  :hide-details="sidecar"
                  :model-value="sub.nextPaymentDate ? formatDateTime(sub.nextPaymentDate, 'DD MMM YYYY') : 'No data'"
                />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field label="BILLING" readonly :hide-details="sidecar" :model-value="billingTextValue(sub)" />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field label="TYPE" :hide-details="sidecar" :model-value="sub.subscriptionType" readonly />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field label="STATE" readonly :hide-details="sidecar" :model-value="sub.subscriptionState" />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 6 : false">
                <v-text-field
                  label="STATUS"
                  :hide-details="sidecar"
                  :class="sub.isActive ? 'active' : 'inactive'"
                  :model-value="sub.isActive ? 'ACTIVE' : 'INACTIVE'"
                  readonly
                />
              </v-col>

              <v-col :class="sidecar ? 'px-1' : ''" :cols="sidecar ? 12 : false">
                <v-text-field
                  style="min-width: 90px"
                  label="PENDING CANCEL"
                  :hide-details="sidecar"
                  :class="sub.pendingCancellation ? 'pending-cancellation' : ''"
                  :model-value="sub.pendingCancellation ? 'YES' : 'NO'"
                  readonly
                />
              </v-col>

              <v-row :style="sidecar ? '' : 'max-width: 60px'">
                <v-col :cols="sidecar ? '3' : false">
                  <v-menu
                    v-if="rights.includes('allowAccountEditAccess')"
                    :disabled="sub.pendingCancellation"
                    location="left"
                  >
                    <template #activator="{ props }">
                      <v-btn
                        variant="plain"
                        icon
                        class="mb-2"
                        :disabled="sub.pendingCancellation"
                        v-bind="props"
                        @click.stop="showAppeasementActions(sub)"
                      >
                        <v-icon v-if="sidecar" color="blue" class="pl-4">mdi-handshake</v-icon>
                        <v-icon v-else>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </v-col>

                <v-col v-if="sidecar && sub.productRatePlanName" class="mt-3 mb-4">
                  <span>Rate plan: {{ sub.productRatePlanName }}</span>
                </v-col>
              </v-row>

              <v-row v-if="sidecar && subscriptions.subscriptions.length > 1" class="justify-center pt-0 pb-0">
                <v-col>
                  <span class="text-subtitle-1">({{ index + 1 }}/{{ subscriptions.subscriptions.length }})</span>
                </v-col>
              </v-row>
            </v-row>

            <div v-if="sub.prepaidPeriods" class="mt-2">
              <span v-if="sub.prepaidPeriods.length" class="text-subtitle-1">Prepaid periods:</span>
              <span v-else class="text-subtitle-1">No prepaid periods found</span>
              <div
                v-if="filterPastPrepaidPeriod(sub.prepaidPeriods).length"
                small
                class="mt-3 mb-1 details-button"
                @click="showPastPeriods = !showPastPeriods"
              >
                <span>{{ showPastPeriods ? 'Hide' : 'Show' }}</span>
                past periods
              </div>

              <v-row>
                <v-col
                  v-for="(period, i) in filterCurrentPrepaidPeriod(sub.prepaidPeriods)"
                  :key="i"
                  cols="4"
                  md="3"
                  xs="5"
                  class="pb-0"
                >
                  {{ formatDateTime(period.start, 'DD MMM YYYY') }}
                  <v-icon>mdi-arrow-right-thin</v-icon>
                  {{ formatDateTime(period.end, 'DD MMM YYYY') }}
                </v-col>
              </v-row>

              <v-row v-if="showPastPeriods">
                <v-col
                  v-for="(period, i) in filterPastPrepaidPeriod(sub.prepaidPeriods)"
                  :key="i"
                  cols="4"
                  md="3"
                  xs="5"
                  class="pb-0"
                >
                  {{ formatDateTime(period.start, 'DD MMM YYYY') }}
                  <v-icon>mdi-arrow-right-thin</v-icon>
                  {{ formatDateTime(period.end, 'DD MMM YYYY') }}
                </v-col>
              </v-row>
            </div>

            <div v-if="sub.productRatePlanName && !sidecar" class="mt-5">
              <span class="text-subtitle-1">Rate plan: {{ sub.productRatePlanName }}</span>
            </div>
          </component>
        </component>

        <v-row
          v-if="!sidecar && subscriptions.subscriptions.length > 0 && subscriptionHistoryEvents"
          class="justify-center mt-8 pb-0"
        >
          <v-btn
            class="change-event-toggle overline v-btn-toggle--dense"
            color="blue"
            variant="text"
            @click="showSubscriptionHistoryEvents = !showSubscriptionHistoryEvents"
          >
            {{ showSubscriptionHistoryEvents ? 'Hide' : 'Show' }} change events
          </v-btn>
        </v-row>

        <v-card-text v-if="!sidecar && subscriptionHistoryEvents" class="px-2 pt-0">
          <div v-if="showSubscriptionHistoryEvents" class="pa-0">
            <v-row class="mt-4">
              <v-col>
                <v-switch v-model="useSupaHistoryEvents" label="Show SUPA subscription events" class="pa-3" />
              </v-col>

              <v-col v-if="useSupaHistoryEvents">
                <v-select
                  v-model="selectedEventTypes"
                  :items="supaEventTypes"
                  label="Add event type filters"
                  hide-selected
                  no-data-text="All filters applied"
                  multiple
                >
                  <template #selection="{ index }">
                    <span v-if="index === 0">Add event type filters</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="useSupaHistoryEvents" class="mx-2 my-4 d-flex ga-2">
              <template v-for="filter in selectedEventTypes" :key="filter">
                <v-chip closable @click:close="removeEventTypeFilter(filter)">
                  <span>{{ filter }}</span>
                </v-chip>
              </template>
            </v-row>

            <!-- Supa history events table -->
            <v-data-table
              v-if="showSubscriptionHistoryEvents && useSupaHistoryEvents"
              v-model:expanded="expandedRows"
              :headers="subscriptionEventsHeaders"
              :items="subscriptionHistoryEvents"
              :items-per-page="25"
              sort-desc
              item-value="createdAt"
              single-expand
              expand-on-click
              show-expand
            >
              <template #item.createdAt="{ item }">
                {{ formatDateTime(item.createdAt, 'DD MMM YYYY HH:mm') }}
              </template>

              <template #item.data-table-expand="{ item }">
                <v-icon v-if="subHistoryEventsExtraHeaders(item).length" @click="subHistoryEventsExtraHeaders(item)">
                  {{ getExpandIcon(item) }}
                </v-icon>
              </template>

              <template #expanded-row="{ item }">
                <td :colspan="subscriptionEventsHeaders.length + 1" class="pa-0">
                  <v-sheet elevation="4">
                    <v-data-table
                      :headers="subHistoryEventsExtraHeaders(item)"
                      :items="[item]"
                      item-value="createdAt"
                      hide-default-footer
                      :style="{ wordBreak: 'break-word' }"
                    />
                  </v-sheet>
                </td>
              </template>
            </v-data-table>

            <v-data-table
              v-if="showSubscriptionHistoryEvents && !useSupaHistoryEvents"
              :headers="auditLogsHeaders"
              :items="subscriptionHistoryEvents"
            >
              <template #item.createdAt="{ item }">
                {{ formatDateTime(item.createdAt, 'DD MMM YYYY HH:mm') }}
              </template>

              <template #item.eventType="{ item }">
                {{ (item as SubscriptionAuditLog).eventType.split('_', 1)[0] }}
              </template>

              <template #item.details="{ item }">
                <span
                  v-for="(detail, j) in getChangeEventDetails(item as SubscriptionAuditLog)"
                  :key="item.subscriptionId + '-auditevent-details-' + j"
                >
                  {{ `${detail}` }}
                </span>
              </template>
            </v-data-table>

            <v-expand-transition>
              <v-row v-show="subChanges" class="mx-0" />
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card-text>

      <v-card-text v-else-if="!dataWait && userEnv !== outoEnv" class="mt-n3">
        <div class="d-flex align-end pa-4">
          <v-icon icon="mdi-alert" color="blue" />
          <p class="mb-0 ml-3">Subscriptions for this member can only be seen in '{{ userEnv }}' version of Darwin</p>
        </div>
      </v-card-text>

      <v-card-text v-else-if="!dataWait && userEnv === outoEnv" class="mt-n3">
        <div class="d-flex justify-center pa-4" style="text-align: center">
          <v-icon icon="mdi-alert" color="blue" />

          <p class="mb-0 ml-3">No subscriptions created / found for this account</p>
        </div>

        <div v-if="startTrialShow" class="mb-3" style="text-align: center">
          <v-tooltip bottom :disabled="!startTrialEnabled">
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn color="primary" :disabled="startTrialEnabled" @click="startTrial()">Start trial</v-btn>
              </div>
            </template>

            <span>This action requires account edit rights</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="cancelModalVisible" width="500">
      <v-card>
        <v-card-title>Cancel Subscription</v-card-title>

        <v-card-text>
          <v-text-field label="CANCEL POLICY" readonly model-value="End Of Billing Period" />

          <v-text-field label="CANCEL DATE" readonly :model-value="formatDateTime(nextBillingDate, 'DD MMM YYYY')" />

          <v-checkbox
            v-if="$featureEnabled('subscriptionCancelImmediatelyFeature')"
            v-model="cancelSubImmediately"
            label="Cancel subscription immediately"
            class="ml-n3"
            :disabled="!rights.includes('rolesPaymentAdmin')"
          />
          <div v-if="$featureEnabled('subscriptionCancelImmediatelyFeature')" style="height: 80px; max-height: 80px">
            <v-alert v-show="cancelSubImmediately" type="warning">
              If you choose to cancel subscription immediately there is no grace period for the member. Use with
              caution.
            </v-alert>
          </div>
        </v-card-text>

        <v-card-actions class="px-4">
          <v-spacer />

          <v-btn variant="text" @click="cancelCancel()">Cancel</v-btn>
          <v-btn variant="text" color="primary" :disabled="!isValid" @click="confirmCancel()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="appeasementActions" :width="sidecar ? '400px' : '500px'">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Appeasement actions</v-card-title>
        <v-container class="pa-0">
          <v-list-item
            style="cursor: pointer"
            :disabled="selectedSub.subscriptionState !== 'TRIAL'"
            prepend-icon="mdi-arrow-expand-horizontal"
            @click="extendTrial(selectedSub)"
          >
            <v-list-item-title>Extend trial period</v-list-item-title>
          </v-list-item>

          <v-list-item
            style="cursor: pointer"
            :disabled="selectedSub.subscriptionState !== 'MEMBER'"
            prepend-icon="mdi-hand-extended"
            @click="openCompMonthsDialog(selectedSub)"
          >
            <v-list-item-title>Give compensation</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="selectedSub.billingAccountState === 'BILLING_ACCOUNT_REQUIRED'"
            style="cursor: pointer"
            prepend-icon="mdi-infinity"
            :disabled="selectedSub.subscriptionState === 'PENDING' || selectedSub.subscriptionState === 'LIFETIME'"
            @click="upgradeLifetimeWithDetails(selectedSub)"
          >
            <v-list-item-title>Upgrade to lifetime</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-else
            style="cursor: pointer"
            prepend-icon="mdi-infinity"
            :disabled="selectedSub.subscriptionState === 'PENDING' || selectedSub.subscriptionState === 'LIFETIME'"
            @click="upgradeLifetime(selectedSub)"
          >
            <v-list-item-title>Upgrade to lifetime</v-list-item-title>
          </v-list-item>
          <v-list-item
            style="cursor: pointer"
            prepend-icon="mdi-archive-cancel"
            :disabled="
              selectedSub.subscriptionState === 'EXPIRED' ||
              selectedSub.subscriptionState === 'PENDING' ||
              selectedSub.isLocked
            "
            @click="cancelSub(selectedSub)"
          >
            <v-list-item-title>Cancel subscription</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            style="cursor: pointer"
            prepend-icon="mdi-cancel"
            :disabled="!rights.includes('rolesMembershipAdmin')"
            @click="openResetSubscription()"
          >
            <v-list-item-title>Reset all subscriptions</v-list-item-title>
          </v-list-item>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoDialog" width="800">
      <v-card>
        <v-card-title class="text-h2 font-weight-medium">Subscription states</v-card-title>

        <v-card-text class="text-body-2 text-grey-darken-4">
          <b>PENDING:</b>
          Subscription has been created, but has not yet been taken into use.There is no expiration date, so in this
          state the subscription is dormant until activated.
          <br />
          <br />

          <b>TRIAL:</b>
          This is almost identical to MEMBER from end user perspective. It can have a different expiration cycle, and it
          can exist without a payment method. Subscriptions in this state also behave differently in systems like
          finance and marketing (like Braze). End date tells when the trial ends and after that either MEMBER status
          begins if subscription is paid or subscription becomes inactive.
          <br />
          <br />

          <b>MEMBER:</b>
          Fixed expiration cycle, specific revenue recognition, non-nullable payment method, etc. Subscription has been
          paid until end date. If subscription payments don't continue, subscription becomes inactive.
          <br />
          <br />

          <b>EXPIRED:</b>
          Subscription has expired and requires user actions to become active again. End date indicates when the
          subscription ended. This date should be in the past (unlike for TRIAL and MEMBER).
          <br />
          <br />

          <b>LIFETIME:</b>
          Some users will have a lifetime subscription which never expires. No expiration cycle, no payment method on
          file, revenue recognition, etc. (DEPRECATED, will be merged with MEMBER in the future).
          <br />
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="user" v-model="extendTrialModal" width="600">
      <v-card>
        <v-card-title class="primary">
          <span class="white--text text-heading-5">Extend trial</span>
        </v-card-title>

        <v-card-text class="px-6 pb-0">
          <p class="mb-0 mt-3 text-subtitle-1">
            You are about to extend trial for {{ user.email || 'this account' }} by
            <b>
              {{ extensionMonths }}
              <span>{{ parseInt(extensionMonths) == 1 ? 'month' : 'months' }}.</span>
            </b>
          </p>

          <v-select
            v-model="extensionReason"
            :items="extentionReasons"
            label="Select the reason"
            item-text="text"
            item-value="value"
          />

          <v-select
            v-model="extensionMonths"
            :items="['1', '2', '3']"
            :item-value="extensionMonths"
            label="Months to extend"
            item-text="text"
          />

          <DatePicker v-model="issueStartDate" label="Issue start date" @valid="issueStartDatevalid = $event" />

          <v-textarea
            v-if="extensionReason === 'Escalations'"
            v-model.trim="escalationReason"
            label="Escalation reason"
            rows="2"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="grey darken-1" variant="text" @click="cancelExtend()">NO</v-btn>
          <v-btn color="primary" :disabled="!extendValidator" variant="text" @click="confirmExtend()">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="user" v-model="compensationDialog" width="600">
      <v-card>
        <v-card-title class="primary">
          <span class="white--text text-heading-5">Compensation</span>
        </v-card-title>

        <v-card-text class="px-6 pb-0">
          <p class="mb-0 mt-3 text-subtitle-1">
            You are about to give compensation months to
            {{ user.email || 'this account' }}
          </p>

          <v-select
            v-model="compensationReason"
            :items="compensationReasons"
            label="Select the reason"
            item-title="text"
            item-value="value"
          />

          <v-select
            v-model="compensationMonths"
            :items="['1', '2', '3', '6', '12']"
            :item-value="compensationMonths"
            label="Months to compensate"
            item-text="text"
          />

          <DatePicker v-model="issueStartDate" label="Issue start date" @valid="issueStartDatevalid = $event" />

          <v-textarea
            v-if="compensationReason === 'Escalations'"
            v-model="compensationEscalationReason"
            hide-details="auto"
            label="Escalation reason*"
            rows="2"
            :rules="[(v: string) => !!v || 'Escalation reason is required']"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" @click="cancelComp()">NO</v-btn>
          <v-btn color="primary" :disabled="!compMonthValidator" variant="text" @click="giveCompMonths()">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ShippingDetails ref="shippingDetails" :member="user" />
    <ResetDialog ref="resetDialog" :member="user" />
  </div>
</template>

<script lang="ts">
  import { capitalize, startCase } from 'lodash-es'

  import { VCarousel, VCarouselItem, VContainer } from 'vuetify/lib/components/index.mjs'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { ResetDialog } from '#common/ResetDialog.vue'

  import { DateTime } from '#mixins/dateTime'

  import {
    auditLogsHeaders,
    compensationReasons,
    extentionReasons,
    subscriptionEventsHeaders,
  } from '#views/members/constants'

  import { ShippingDetails } from '#views/members/billing/ShippingDetails.vue'

  import { AppStore, DiagnosticsStore, SubscriptionStore } from '#stores'

  import {
    BillingAccountState,
    Member,
    OuraSubscriptionState,
    PrepaidPeriods,
    SubscriptionAuditLog,
    SubscriptionInfo,
    Subscriptions,
  } from '#types'

  @Component({
    components: {
      VCarousel,
      VCarouselItem,
      VContainer,
    },
  })
  class OuraSubscription extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public link!: string
    @Prop() public sidecar!: boolean

    public declare $refs: {
      shippingDetails: ShippingDetails
      resetDialog: ResetDialog
    }

    public appStore = new AppStore()
    public subscriptionStore = new SubscriptionStore()
    public diagnosticsStore = new DiagnosticsStore()

    public errorText = ''

    public extensionReason = ''
    public escalationReason = ''
    public extensionMonths = '1'

    public compensationReason = ''
    public compensationEscalationReason = ''
    public compensationMonths = '1'

    public issueStartDate = ''
    public issueStartDatevalid = false

    public nextBillingDate = ''

    public extendTrialModal = false
    public compensationDialog = false
    public subChanges = false
    public infoDialog = false
    public appeasementActions = false
    public cancelModalVisible = false
    public isValid = false
    public selectedSub: SubscriptionInfo = {
      id: '',
      subscriptionState: OuraSubscriptionState.TRIAL,
      subscriptionType: '',
      startDate: '',
      endDate: '',
      nextBillingDate: '',
      nextPaymentDate: '',
      subscriptionFee: null,
      isActive: false,
      pendingCancellation: false,
      prepaidPeriods: [],
      productRatePlanName: '',
      billingAccountState: BillingAccountState.BILLING_ACCOUNT_EXISTS,
      isLocked: false,
    }

    public showSubscriptionHistoryEvents = false
    public subscriptionEventsHeaders = subscriptionEventsHeaders
    public subscriptionEventsExtraHeaders = []
    public auditLogsHeaders = auditLogsHeaders
    public useSupaHistoryEvents = true
    public expandedRows: string[] = []
    public selectedEventTypes: string[] = []
    public resetCheckBoxValue = false
    public resetError: string | null = null

    public outoEnv = import.meta.env.VITE_APP_ENV == 'release' ? 'production' : import.meta.env.VITE_APP_ENV

    public extentionReasons = extentionReasons

    public highlightedSubscription: string | null = null

    public compensationReasons = compensationReasons.flat()

    private interval: number | undefined = undefined

    private subscriptionId: string | undefined = undefined

    public showPastPeriods: boolean = false

    public cancelSubImmediately = false

    public get auth() {
      return this.appStore.user
    }

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get userEnv() {
      return this.user?.env?.environment
        ? this.user.env.environment?.replace('dev', 'preview').replace('stage', 'staging')
        : this.outoEnv || 'local'
    }

    public get dataWait(): boolean {
      const storeReady = !this.subscriptionStore.waitingForData()
      const storeWideDataWait = this.subscriptionStore.dataWait
      if (storeReady && !storeWideDataWait) {
        return false
      }
      return true
    }

    public get subscriptions(): Subscriptions {
      return this.subscriptionStore.subscriptions
    }

    public get subscriptionAuditLogs() {
      return this.subscriptionStore.subscriptionAuditLogs
    }

    public get supaHistoryEvents() {
      return this.subscriptionStore.subscriptionHistoryEvents
    }

    public get filteredSupaHistoryEvents() {
      if (this.selectedEventTypes.length === 0) {
        return this.supaHistoryEvents
      }

      return this.supaHistoryEvents.filter((event: any) => this.selectedEventTypes.includes(event.type))
    }

    public get subscriptionHistoryEvents() {
      return !this.useSupaHistoryEvents ? this.subscriptionAuditLogs : this.filteredSupaHistoryEvents
    }

    public get subscriptionEventsHeadersKey() {
      return this.subscriptionEventsHeaders.flatMap((header: { title: string; key: string }) => header.key)
    }

    public get supaEventTypes(): { title: string; value: string }[] {
      return this.subscriptionStore.subscriptionHistoryEvents
        .map((event: any) => event.type)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((type: string) => ({
          title: capitalize(type.split('_').join(' ')),
          value: type,
        }))
    }

    public get startTrialShow(): boolean {
      return !this.dataWait && this.subscriptions.subscriptions.length == 0
    }

    public get startTrialEnabled(): boolean {
      return !this.rights.includes('allowAccountEditAccess')
    }

    /**
     * Check if the trial extension modal form is valid
     */
    public get extendValidator() {
      if (this.extensionReason === 'Escalations') {
        return (
          this.extensionReason &&
          this.escalationReason &&
          this.extensionMonths &&
          this.issueStartDatevalid &&
          this.isValid
        )
      }
      return this.extensionReason && this.extensionMonths && this.issueStartDatevalid && this.isValid
    }

    /**
     * Check if the trial extension modal form is valid
     */
    public get compMonthValidator() {
      if (this.compensationReason === 'Escalations') {
        return (
          this.compensationReason &&
          this.compensationEscalationReason &&
          this.compensationMonths &&
          this.issueStartDatevalid &&
          this.isValid
        )
      }
      return this.compensationReason && this.compensationMonths && this.issueStartDatevalid && this.isValid
    }

    public get signupFlowDiagnosticsMessage() {
      const signupflowMessage = this.diagnosticsStore.signupFlowDiagnostics?.extraInfo?.items[0].message
        ? this.diagnosticsStore.signupFlowDiagnostics?.extraInfo?.items[0].message
        : ''
      return signupflowMessage
    }

    public showAppeasementActions(subscription: SubscriptionInfo) {
      this.selectedSub = subscription
      this.appeasementActions = true
    }

    public subHistoryEventsExtraHeaders(item: any) {
      return (
        Object.keys(item).reduce((acc: any, cur: string) => {
          if (!this.subscriptionEventsHeadersKey.includes(cur)) {
            acc.push({ key: cur, title: startCase(cur) })
          }
          return acc
        }, []) || []
      )
    }

    public getExpandIcon(item: { createdAt: string }): string {
      if (this.expandedRows.includes(item.createdAt)) {
        return 'mdi-chevron-up'
      } else {
        return 'mdi-chevron-down'
      }
    }

    public billingTextValue(subscription: SubscriptionInfo): string {
      if (subscription.subscriptionFee) {
        const { amount, currency } = subscription.subscriptionFee.price
        const periodType = subscription.subscriptionFee.billingPeriod.periodType
        return `${amount} ${currency} / ${periodType}`
      } else {
        return 'No data'
      }
    }

    @Watch('user')
    protected onUuidChange(_val: any, _prevVal: string) {
      this.updateSubscriptions(true)
    }

    @Watch('$route', { immediate: true })
    protected onRouteChanged(val: any) {
      if (val.query?.tab == 'accountinfo' && localStorage.getItem('OuraAdminDebugMode') === 'true') {
        this.runDebugMode()
      }
    }

    public filterCurrentPrepaidPeriod(prepaidPeriods: PrepaidPeriods[]) {
      const currentDate = new Date()
      const filteredPeriods = prepaidPeriods.filter((period: PrepaidPeriods) => {
        const endDate = new Date(period.end)
        return endDate >= currentDate
      })
      return filteredPeriods
    }

    public filterPastPrepaidPeriod(prepaidPeriods: PrepaidPeriods[]) {
      const currentDate = new Date()
      const filteredPeriods = prepaidPeriods.filter((period: PrepaidPeriods) => {
        const endDate = new Date(period.end)
        return endDate < currentDate
      })
      return filteredPeriods
    }

    public mounted() {
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')

      this.updateSubscriptions(true)
      this.interval = setInterval(this.updateSubscriptions, 5 * 60 * 1000)
    }

    public beforeUnmount() {
      clearInterval(this.interval)
    }

    public runDebugMode() {
      console.info('%c ******BEGIN DEBUG MODE*****', 'background: #222; color: #bada55')
      console.info(
        ' outoEnv:',
        this.outoEnv,
        '\n userEnv:',
        this.userEnv,
        '\n subscriptions:',
        this.subscriptions.subscriptions,
        '\n defaultPaymentMethodCardExpired:',
        this.subscriptions.defaultPaymentMethodCardExpired,
        '\n dunningLevel:',
        this.subscriptions.dunningLevel,
        '\n isPaymentMethodSet:',
        this.subscriptions.isPaymentMethodSet,
      )
      console.info('%c  ******EMD DEBUG MODE*******', 'background: #222; color: #bada55')
    }

    public moreInfo() {
      if (this.link) {
        logEvent(this.$analytics, `${this.sidecar ? 'sidecar' : 'dashboard'}_subscription_moreInfo_clicked`, {
          category: `${this.sidecar ? 'Sidecar' : 'Dashboard'}'`,
          action: `${this.sidecar ? 'Sidecar' : 'Dashboard'} moreinfo clicked'`,
          label: `${this.sidecar ? 'Sidecar' : 'Dashboard'} moreinfo clicked'`,
          page_title: `${this.sidecar ? 'Sidecar' : 'Dashboard'}`,
          page_location: window.location.toString().split('?')[0],
        })
        window.open(this.link + '#accountinfo', '_blank')
      }
    }

    public extendTrial(subscription: SubscriptionInfo) {
      this.extendTrialModal = true
      this.isValid = true
      this.subscriptionId = subscription.id
    }

    public openCompMonthsDialog(subscription: SubscriptionInfo) {
      this.compensationDialog = true
      this.isValid = true
      this.subscriptionId = subscription.id
    }

    public async cancelSub(subscription: SubscriptionInfo) {
      this.subscriptionId = subscription.id
      this.nextBillingDate = subscription.nextBillingDate || ''

      this.cancelModalVisible = true
      this.isValid = true
    }

    public async cancelCancel() {
      this.cancelModalVisible = false
      this.isValid = false
    }

    public async confirmCancel() {
      logEvent(this.$analytics, 'subscription_cancel', {
        category: 'Subscription:cancel',
        action: 'Click cancel subscription',
        label: 'Click cancel subscription',
        page_title: 'Oura user',
        page_location: window.location.toString().split('?')[0],
      })
      this.isValid = false
      const response = await this.subscriptionStore.cancelSubscription({
        userId: this.user.uuid,
        subscriptionId: this.subscriptionId ?? '',
        cancelImmediately: this.cancelSubImmediately,
      })
      if (!response && this.subscriptionStore.getRequestError('cancelSubscription')?.userMessage) {
        this.errorText = this.subscriptionStore.getRequestError('cancelSubscription')!.userMessage!
        this.cancelModalVisible = false
      } else {
        this.errorText = ''
        this.cancelModalVisible = false
      }
    }

    public async cancelExtend() {
      this.extendTrialModal = false
      this.isValid = false
      this.extensionReason = ''
    }

    public async confirmExtend() {
      if (this.extensionReason) {
        logEvent(this.$analytics, 'subscription_extend', {
          category: 'Subscription:extend',
          action: 'Click extend subscription',
          label: 'Click extend subscription',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
        this.isValid = false

        await this.subscriptionStore.extendSubscription({
          userId: this.user.uuid,
          subscriptionId: this.subscriptionId ?? '',
          extensionReason: this.extensionReason,
          escalationReason: this.escalationReason,
          issueStartDate: this.issueStartDate,
          extensionMonths: Number(this.extensionMonths),
        })
        this.errorText = this.subscriptionStore.extendSubscriptionError
      }
      this.extensionReason = ''
      this.extendTrialModal = false
      this.extensionMonths = '1'
    }

    public async upgradeLifetime(subscription: SubscriptionInfo) {
      const text = `You are about to convert subscription for  ${
        this.user?.email || 'this account'
      } to free for LIFETIME which is a permanent action.`

      const confirm = await this.$confirm('Upgrade subscription', text)

      if (confirm) {
        logEvent(this.$analytics, 'subscription_lifetime', {
          category: 'Subscription:lifetime',
          action: 'Click lifetime subscription',
          label: 'Click lifetime subscription',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })

        const response = await this.subscriptionStore.upgradeSubscription({
          userId: this.user.uuid,
          subscriptionId: subscription.id,
        })

        if (!response && this.subscriptionStore.getRequestError('upgradeSubscription')?.userMessage) {
          this.errorText = this.subscriptionStore.getRequestError('upgradeSubscription')!.userMessage!
        }
      }
    }

    /**
     * Upgrade to lifetime subscription and ask for missing shipping details
     * @param subscription Subscription info
     */
    public async upgradeLifetimeWithDetails(subscription: SubscriptionInfo) {
      let text = `You are about to convert subscription for ${
        this.user?.email || 'this account'
      } to free for LIFETIME which is a permanent action.
        To upgrade subscription, enter missing shipping details.`

      await this.$refs.shippingDetails.open('upgradeLifetime', 'Upgrade subscription', text, subscription)
    }

    /**
     * Reset dunning subscription
     */
    public async openResetDunningHistory() {
      const text = "Are you sure you want to reset ALL of this member's dunning history?"
      await this.$refs.resetDialog.open('resetDunning', 'Reset dunning history', text)
      return
    }
    // .. end reset dunning history

    /**
     * Reset  subscription
     */
    public async openResetSubscription() {
      const text =
        "Are you sure you want to reset ALL of this member's subscriptions? Resetting subscriptions will cancel all user's subscriptions and reset the account to initial status."
      await this.$refs.resetDialog.open('resetSubscription', 'Reset all subscriptions', text)
    }
    // .. end reset subscription

    /**
     * When "start trial" button is clicked ask for shipping details and start trial
     */
    public async startTrial() {
      await this.$refs.shippingDetails.open(
        'startTrial',
        'Start trial',
        'Shipping details are required to start trial.',
      )
    }

    public cancelComp() {
      this.compensationDialog = false
      this.isValid = false
    }

    public async giveCompMonths() {
      if (this.compensationReason) {
        logEvent(this.$analytics, 'subscription_compensate', {
          category: 'Subscription:compensate',
          action: 'Click to compensate subscription',
          label: 'Click to compensate subscription',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })

        this.isValid = false

        const response: any = await this.subscriptionStore.compensateSubscription({
          userId: this.user.uuid,
          subscriptionId: this.subscriptionId,
          compensationReason: this.compensationReason,
          compensationEscalationReason: this.compensationEscalationReason,
          issueStartDate: this.issueStartDate,
          compensationMonths: Number(this.compensationMonths),
        })

        this.errorText = response?.error ? response.error?.message || '' : ''
      }

      this.compensationReason = ''
      this.compensationDialog = false
      this.compensationEscalationReason = ''
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')
      this.compensationMonths = '1'
    }

    public getChangeEventDetails(event: SubscriptionAuditLog): string[] {
      // Get details for subscription change events table
      const eventType = event.eventType
      const details: string[] = []
      switch (eventType) {
        case 'CANCEL_USER_SUBSCRIPTION':
          if (event.json?.cancelDate) {
            details.push(`Cancel date: ${this.formatDateTime(event.json?.cancelDate, 'DD MMM YYYY')}`)
          } else if (event.json?.cancelPolicy) {
            details.push(`Cancel policy: ${event.json?.cancelPolicy}`)
          }
          break
        case 'MODIFY_USER_SUBSCRIPTION':
          if (event.json?.changeSubscriptionType == 'LIFETIME_FREE') {
            details.push(`Upgraded to lifetime`)
            break
          }
          if (event.json?.extendTrialMonths) {
            details.push(`Extend trial by ${event.json.extendTrialMonths} month(s).`)
          }
          if (event.json?.extendTrialReason) {
            details.push(`Reason: ${event.json.extendTrialReason}`)
          }
          if (event.json?.compensationMonths) {
            details.push(`Gave ${event.json.compensationMonths} month(s) of compensation.`)
          }
          if (event.json?.compensationReason) {
            details.push(`Reason: ${event.json.compensationReason}`)
          }
          break
        case 'REFUND_USER_PAYMENT':
          if (event.json?.refundReason) {
            details.push(`Reason: ${event.json.refundReason}`)
          }
      }
      return details
    }

    private updateSubscriptions(initial: boolean) {
      if (this.user) {
        this.subscriptionStore.getSubscriptions({
          userId: this.user.uuid,
          initial,
        })
        this.subscriptionStore.getSubscriptionAuditLogs({
          userId: this.user.uuid,
        })
        this.subscriptionStore.getSubscriptionHistoryEvents({
          userId: this.user.uuid,
        })
        this.diagnosticsStore.getSignupFlowDiagnostics(this.user.uuid)
      }
    }

    public removeEventTypeFilter(filter: string) {
      this.selectedEventTypes = [...this.selectedEventTypes.filter((item: string) => item !== filter)]
    }
  }

  export default toNative(OuraSubscription)
</script>

<style lang="scss" scoped>
  :deep(.v-text-field) {
    font-size: 15px;
    &.active {
      input {
        color: green;
      }
    }

    &.inactive,
    &.pending-cancellation {
      input {
        color: red;
      }
    }
  }

  :deep(.v-text-field) {
    font-size: 15px;
    &.active {
      input {
        color: green;
      }
    }

    &.inactive,
    &.pending-cancellation {
      input {
        color: red;
      }
    }
  }

  :deep(.v-btn:is(.change-event-toggle)) {
    padding: 0 11px;
    font-size: 0.65rem !important;
    margin-top: -30px;
  }

  :deep(.subscription-change-event-table) {
    min-width: 100%;
  }

  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }

  :deep(.subinfo) {
    input {
      font-size: 14px !important;
      padding-left: 8px;
      padding-right: 8px;
    }

    .v-field-label--floating {
      margin-left: 8px;
      margin-right: 8px;
      font-size: 10px !important;
    }
  }
</style>
