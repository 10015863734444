import { getWarrantyInfo } from '#utils/user/warrantyDetails'

import { Nullable, WarrantyData, WarrantyInfo, WarrantyInfoTitles } from '#types'

const warrantyInfoTitles: WarrantyInfoTitles = {
  warrantyStatus: 'Warranty status',
  warrantyStartDate: 'Warranty start date',
  escStartDate: 'ESC start date',
  soTransactionId: 'SO transaction ID',
  fulfillmentTimestamp: 'Order status update date',
  warrantyEndDate: 'Warranty end date',
  escEndDate: 'ESC end date',
  retailPartner: 'Retail partner',
  retailChannel: 'Retail channel',
}
/**
 * Extract WarrantyInfo from WarrantyData
 *
 * TODO:
 * - Handle + test case "When ESC end date is available (and later than warranty end, which it should be but who
 * knows), it should be used as end date in warranty diagnostic. MXPRODS-2751
 */
export function extractWarrantyInfoFromWarrantyData(warrantyData: WarrantyData): WarrantyInfo {
  const ringset = warrantyData.ringset
  const warrantyStartDate = ringset?.warrantyStartDate ?? null
  const warrantyEndDate = ringset?.warrantyEndDate ?? null

  // maybe we could just store order status here and move the title logic to getWarrantyInfoTitles()?
  let fulfillmentStatusTitle = 'Order status update date'
  if (warrantyData?.fulfillment?.status) {
    fulfillmentStatusTitle = 'Order ' + warrantyData.fulfillment.status.toLowerCase() + ' date'
  }
  const escEndDate = ringset?.escEndDate ?? null
  const { state, effectiveEndDate } = getWarrantyInfo(warrantyStartDate, warrantyEndDate, escEndDate)

  const retailPartner =
    warrantyData?.order?.retailPartner && warrantyData.order.retailPartner.length > 0
      ? warrantyData?.order.retailPartner
      : null

  const retailChannel =
    warrantyData?.order?.businessLine && warrantyData.order.businessLine.length > 0
      ? warrantyData?.order.businessLine
      : null

  return {
    warrantyStatus: state,
    warrantyStartDate: warrantyStartDate,
    warrantyEndDate: effectiveEndDate ?? null,
    escStartDate: ringset?.escStartDate ?? null,
    escEndDate: escEndDate,
    soTransactionId: warrantyData?.order?.transactionId ?? null,
    fulfillmentStatusTitle: fulfillmentStatusTitle,
    fulfillmentTimestamp: warrantyData?.fulfillment?.timestamp ?? null,
    retailPartner: retailPartner,
    retailChannel: retailChannel,
  }
}

export function getWarrantyInfoTitles(warrantyInfo: Nullable<WarrantyInfo>): WarrantyInfoTitles {
  const fulfillmentStatusTitle = warrantyInfo?.fulfillmentStatusTitle ?? 'Order status update date'
  return { ...warrantyInfoTitles, fulfillmentTimestamp: fulfillmentStatusTitle }
}

/**
 * Human readable string representation of WarrantyInfo
 */
export function warrantyInfoToString(warrantyInfo: WarrantyInfo, warrantyInfoTitles: WarrantyInfoTitles): string {
  const lineFeed = '\n'
  let contents = ''
  let i = 0
  for (const [key, value] of Object.entries(warrantyInfoTitles)) {
    if (i > 0) {
      contents += lineFeed
    }
    contents += value + ': ' + warrantyInfo[key as keyof WarrantyInfo]
    i++
  }
  return contents
}
