import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

@Store()
export class RemoteStore extends BaseStore {
  public async sendBackupTrigger(uuid: string) {
    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${uuid}/notifications`,
        data: {
          message: {
            data: {
              'darwin-remote-command': {
                scheme: 'oura://v1/backup',
              },
            },
          },
        },
      },
      'triggerBackup',
      uuid,
      null,
    ).catch((_axiosError: AxiosError) => {
      //ignore for now
      return null
    })
    console.log(response)
  }
}
