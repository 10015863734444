<template>
  <div>
    <AccountEmails :member="user" :account-info="true" />

    <OuraSubscription class="mt-8" :user="user" />

    <PaymentHistory
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <PaymentMethods
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <InvoicesListing
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <CreditMemos
      v-if="rights.includes('rolesPaymentViewer') || rights.includes('rolesPaymentAdmin')"
      class="mt-8"
      :user="user"
    />

    <RingEntitlements class="mt-8" :ring-serial="ring ? ring.serialNumber : ''" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  import { Member, Ring } from '#types'

  @Component
  class MembershipDetails extends Vue {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]

    /**
     * Use email V2 (=cloud management user details)
     */

    public appStore = new AppStore()

    public get rights() {
      return this.appStore.activeRights || []
    }
  }

  export default toNative(MembershipDetails)
</script>
