<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar>
    <v-app-bar-title>
      Oura Teams Organizations {{ organizations?.length ? `(${organizations.length})` : '(0)' }}
    </v-app-bar-title>

    <v-spacer />

    <v-switch v-model="internal" label="Hide internal test / personal organizations" class="mt-1 mr-4" />
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="7">
        <div class="text-h5 font-weight-light">Oura Teams Organizations management</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="!hasViewAccess && !hasAdminAccess">
            You don't have permissions to view Oura Teams Organizations
          </template>
          <template v-else>You have permissions to view and edit Oura Teams Organizations</template>
        </div>
      </v-col>

      <v-col md="5" cols="12" class="d-flex text-right">
        <v-spacer />

        <v-menu left offset="8" :disabled="!hasAdminAccess">
          <template #activator="{ props }">
            <v-tooltip location="bottom">
              <template #activator="{ props: tooltipProps }">
                <v-btn
                  v-bind="{ ...props, ...tooltipProps }"
                  color="primary"
                  text="Create new"
                  append-icon="mdi-menu-down"
                />
              </template>
              Create teams and accounts
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="accountDialog = true">
              <v-list-item-title>Create New Account</v-list-item-title>
            </v-list-item>

            <v-list-item @click="createOrganization()">
              <v-list-item-title>Create New Organization</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col class="d-flex" cols="12" sm="12" md="7">
        <v-text-field
          v-model="filter"
          autofocus
          label="Search Oura teams"
          append-inner-icon="mdi-magnify"
          placeholder="Enter text and press enter to search..."
        />
      </v-col>

      <v-col class="d-flex align-center" cols="12" sm="12" md="5">
        <v-spacer />

        <v-btn color="blue" class="mr-2" prepend-icon="mdi-file-download" @click="csvDownload()">CSV</v-btn>
      </v-col>
    </v-row>

    <v-sheet v-if="hasViewAccess || hasAdminAccess" class="mt-8">
      <v-data-table
        :items="organizations"
        :search="filter"
        :headers="headers"
        :items-per-page="100"
        :loading="dataWait"
        :custom-filter="filterOrganizations"
        :sort-by="[{ key: 'createdAt', order: 'desc' }]"
        @click:row="(_e: any, val: any) => navigateToOrganization(val.item as TeamsOrganization)"
      >
        <template #[`item.name`]="{ item }">
          <HoverCopy
            :text="item.name"
            :data="item.name + ' - ' + item.uid"
            :message="'Copy name and uid to clipboard'"
          />
        </template>

        <template #[`item.createdAt`]="{ item }">
          <div class="text-no-wrap">
            {{ formatDateTime(item.createdAt, 'DD MMM YYYY') }}
          </div>
        </template>

        <template #[`item.type`]="{ item }">
          <div class="text-capitalize">
            {{ orgTypes[item.type] }}
          </div>
        </template>

        <template #[`item.admins`]="{ item }">
          <div>
            {{ item.admins.map((admin) => admin.email).join(', ') }}
          </div>
        </template>

        <template #[`item.subscriptionStatus`]="{ item }">
          <div>
            {{ item.subscriptionStatus === 'active' ? 'paid' : item.subscriptionStatus }}
          </div>
        </template>

        <template #[`item.validTo`]="{ item }">
          <div>
            <span class="text-no-wrap" :class="{ expired: item.validTo && +new Date(item.validTo) < +new Date() }">
              {{ item.validTo ? formatDateTime(item.validTo, 'DD MMM YYYY') : 'No end date' }}
            </span>
          </div>
        </template>

        <template #[`item.externalId`]="{ item }">
          {{ item.externalId === ouraTestingExternalId ? 'X' : '' }}
        </template>
      </v-data-table>
    </v-sheet>

    <router-view />
  </v-container>

  <CreateOrganization
    v-if="createDialog"
    :dialog="createDialog"
    :organization="organization"
    :create="!organization?.uid"
    @close="(organization = null), (createDialog = false)"
  />

  <v-dialog v-model="accountDialog" width="500" @after-leave="closeCreateAcc()">
    <v-card>
      <v-card-title>Create account</v-card-title>

      <v-card-text>
        <v-form v-model="emailValid">
          <v-text-field v-model="accountEmail" label="Email address" hide-details="auto" :rules="emailRules" />
        </v-form>

        <v-alert v-if="createAccDone" tile class="px-5" type="success">The instruction was sent to the email</v-alert>

        <v-alert v-if="createAccErr" tile class="px-5" type="error" color="red">
          {{ createAccErr }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeCreateAcc()">{{ createAccDone ? 'Close' : 'Cancel' }}</v-btn>
        <v-btn v-if="!createAccDone" color="primary" :disabled="!emailValid" @click="createAccount()">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { orgTypesListNames, ouraTestingExternalId, teamsHeaders } from '#views/teams/constants'

  import { getEmailValidationErrorMessage, isEmailValid } from '#utils/user/emailValidation'

  import { AppStore, TeamsStore } from '#stores'

  import { TeamsOrganization } from '#types'

  @Component
  class TeamsView extends mixins(DateTime) {
    @Prop() public orgID!: string

    public filter = ''
    public accountEmail = ''

    public internal = false
    public emailValid = false
    public accountDialog = false
    public createDialog = false

    public headers = teamsHeaders
    public orgTypes = orgTypesListNames
    public ouraTestingExternalId = ouraTestingExternalId

    public organization: Partial<TeamsOrganization> | null = null
    private env = import.meta.env.VITE_APP_ENV

    public emailRules = [(v: string) => isEmailValid(v, this.env) || getEmailValidationErrorMessage(this.env)]

    public createAccDone = false

    public appStore = new AppStore()
    public teamsStore = new TeamsStore()

    public get rights() {
      return this.appStore.activeRights || []
    }

    public get hasAdminAccess() {
      return this.rights.includes('rolesOuraTeamsAdmin')
    }

    public get hasViewAccess() {
      return this.rights.includes('rolesOuraTeamsAdmin') || this.rights.includes('rolesOuraTeamsViewer')
    }

    public get organizations() {
      return !this.internal ? this.teamsStore.organizations : this.teamsStore.filteredOrganizations
    }

    public get createAccErr() {
      return this.teamsStore.createAccErr
    }

    public get dataWait() {
      return this.teamsStore.dataWait
    }

    public mounted() {
      this.teamsStore.listOrganizations()
    }

    public csvDownload() {
      const orgCSV = this.organizations.map((org: TeamsOrganization) => {
        return {
          'Name': org.name,
          'ID': org.uid,
          'Creation Date': this.formatDateTime(org.createdAt, 'DD MMM YYYY'),
          'Type': org.type,
          'Admins': org.admins.reduce((acc: string, cur: { email: string; userUid: string }, index) => {
            acc += `${index === 0 ? '' : ','}${cur.email || ''}`
            return acc
          }, ''),
          'Account Manager': org.accountManagerEmail ?? '',
          'Netsuite Customer ID': org.externalId,
          'Permissions': org.permissions.join(','),
          'Subscription status': org.subscriptionStatus,
          'Current Subscription End Date': org.validTo
            ? this.formatDateTime(org.validTo, 'DD MMM YYYY')
            : 'No end date',
          'Number of Seats(Used)': org.memberCount,
          'Number of Coaches/Admins': org.coachCount,
          'Number of Groups': org.memberCount,
        }
      })

      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [Object.keys(orgCSV[0]).join(';'), ...orgCSV.map((org: any) => Object.values(org).join(';'))]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'teams.csv')
      link.click()
    }

    public async createAccount() {
      const res = await this.teamsStore.createAccount(this.accountEmail)
      if (res) {
        this.createAccDone = true
      }
    }

    public closeCreateAcc() {
      this.accountDialog = false
      this.createAccDone = false
      this.accountEmail = ''
      this.teamsStore.createAccErr = ''
    }

    public createOrganization() {
      this.organization = {
        name: '',
        type: 'coach',
        permissions: [],
        adminEmail: null,
        externalId: null,
        accountManagerEmail: null,
      }

      this.createDialog = true
    }

    public filterOrganizations(_value: string, search: string | null, organization: any) {
      organization = organization.raw
      return !search
        ? true
        : organization.uid?.includes(search) ||
            organization.type?.includes(search.toLowerCase()) ||
            organization.name?.toLowerCase().includes(search.toLowerCase()) ||
            (organization.admin !== null &&
              organization.admins?.find((a: any) => a.email?.toLowerCase().includes(search.toLowerCase()))) ||
            (organization.accountManagerEmail !== null &&
              organization.accountManagerEmail?.toLowerCase().includes(search.toLowerCase()))
    }

    public navigateToOrganization({ uid }: TeamsOrganization) {
      if (!this.hasViewAccess || !uid || this.$route.path.endsWith(uid)) {
        return
      }
      this.$router.push(`/teams/${uid}`)
    }
  }
  export default toNative(TeamsView)
</script>

<style lang="scss" scoped>
  :deep(.expired) {
    color: red;
  }

  :deep(.v-switch) {
    flex: 0 1 auto;
    .v-selection-control {
      flex-direction: row-reverse;
    }
    .v-label {
      margin-right: 16px;
    }
  }

  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }
</style>
