<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-app-bar title="Oura rings / chargers" />

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Access Oura rings and chargers information</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          View order info / warranty status / return status and linked accounts
        </div>
      </v-col>

      <v-col class="text-right" md="3" cols="12">
        <v-btn
          text="Run search"
          color="primary"
          :disabled="isLoading || !routeParams.search"
          @click="searchRing()"
          @keyup.enter="searchRing()"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="5">
        <v-text-field
          v-model.trim="routeParams.search"
          autofocus
          label="Search for devices"
          append-inner-icon="mdi-database-search"
          :hide-details="false"
          :error="!searchIsValid"
          :error-messages="searchErrors"
          :placeholder="`Enter keyword to search and press enter...`"
          @keyup.enter="searchRing()"
        />
      </v-col>

      <v-col cols="2">
        <v-select
          v-model="routeParams.searchType"
          label="Search type"
          :items="searchTypes"
          @update:model-value="validateSearch()"
        />
      </v-col>

      <v-col cols="3">
        <v-select
          clearable
          label="Status filter"
          placeholder="Select extra filtering..."
          :items="searchFilters"
          :model-value="routeParams.searchFilter || null"
          @update:model-value="routeParams.searchFilter = $event"
        />
      </v-col>
      <v-col class="d-flex align-start justify-end" cols="2">
        <v-checkbox
          class="mt-n2"
          label="Use partial match"
          :disabled="!partialSearchEnabled"
          :model-value="routeParams.partialSearch === 'true'"
          @update:model-value="routeParams.partialSearch = $event?.toString() || 'false'"
          @keyup.enter="searchRing()"
        >
          <v-tooltip activator="parent" location="top" :disabled="false">
            Match ring and charger serials partially from the start of the string.
          </v-tooltip>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-sheet class="mt-4">
      <v-data-table
        item-class="type"
        :headers="headers"
        :loading="isLoading"
        :items="ringSearchResults"
        :items-per-page="itemsPerPage"
        :no-data-text="isLoading ? 'Loading search results...' : 'No matches / results found'"
        :no-results-text="routeParams.search ? 'Press enter to perform search' : 'No matching devices found'"
        @click:row="(_event: any, item: any) => gotoRingDetails(item)"
      >
        <template #[`item.ringSerialNumber`]="{ item }">
          {{ item.ringSerialNumber || 'Not available' }}
        </template>

        <template #[`item.netsuiteSalesOrderId`]="{ item }">
          {{ item.netsuiteSalesOrderId || 'Not available' }}
        </template>

        <template #[`item.chargerSerialNumber`]="{ item }">
          {{ item.chargerSerialNumber || 'Not available' }}
        </template>

        <template #[`item.orderStatus`]="{ item }">
          {{ item.orderStatus || 'Not available' }}
        </template>

        <template #[`item.orderTimestamp`]="{ item }">
          {{ item.orderTimestamp || 'Not available' }}
        </template>

        <template #[`item.returnReason`]="{ item }">
          {{ item.returnReason || 'Not available' }}
        </template>

        <template #[`item.returnStatus`]="{ item }">
          {{ item.returnStatus || 'Not available' }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<script lang="ts">
  import { Component, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import {
    ringSearchFilters,
    ringSearchHeaders,
    ringSearchMaxLength,
    ringSearchMinLength,
    ringSearchTypeName,
    ringSearchTypes,
  } from '#views/devices/constants'

  import { RingsStore } from '#stores'

  import { RingSearchRequest } from '#types'

  @Component({})
  class DevicesSearchView extends mixins(RouteParams) {
    public routeParams: any = {
      search: '',
      searchType: 'ringSerial',
      searchFilter: '',
      partialSearch: 'false',
    }

    public itemsPerPage = 100
    public searchPageSize = 500

    public searchIsValid = true

    public searchErrors: string[] = []

    public readonly headers = ringSearchHeaders
    public readonly searchTypes = ringSearchTypes

    public readonly searchFilters = ringSearchFilters
    public readonly ringSearchTypeName = ringSearchTypeName

    protected readonly ringsStore = new RingsStore()

    public get isLoading() {
      return this.ringsStore.dataWait
    }

    public get ringsCount() {
      return (
        (this.ringSearchResults?.length >= this.searchPageSize ? '>= ' : '') + (this.ringSearchResults?.length || '0')
      )
    }

    public get ringSearchResults() {
      return this.ringsStore.ringSearchResult
    }

    public get partialSearchEnabled() {
      if (this.routeParams.searchType === 'netsuiteOrderId' || this.routeParams.searchType == 'netsuiteReturnId') {
        return false
      }

      return true
    }

    public mounted() {
      this.ringsStore.ringSearchResult = []

      this.searchRing()
    }

    public searchRing() {
      this.validateSearch()

      if (!this.searchIsValid || !this.routeParams.search) {
        return
      }

      let searchRequest: RingSearchRequest = {
        pageSize: this.searchPageSize,
        search: this.routeParams.search,
        searchType: this.routeParams.searchType,
        partialSearch: this.routeParams.partialSearch === 'true',
      }

      if (this.routeParams.searchFilter) {
        searchRequest['searchFilter'] = this.routeParams.searchFilter
      }

      const filteredSearchRequest = this.filterSearchRequest(searchRequest)

      this.ringsStore.search(filteredSearchRequest)
    }

    /**
     * Validates given search keyword.
     * Keyword must be at least `ringSearchMinLength` characters long and ringSearchMinLength` characters short.
     * Keyword must also match our safe-string regex.
     * @private
     */
    public validateSearch() {
      this.searchErrors = []
      this.searchIsValid = true

      if (!this.routeParams.search) {
        return
      }

      if (!this.partialSearchEnabled) {
        this.routeParams.partialSearch = 'false'
      }

      // Test safe-string regex match
      const searchRegex = new RegExp(/^[0-9a-zA-Z_,.-]+$/)
      const searchRegexInvalid = !searchRegex.test(this.routeParams.search)

      if (searchRegexInvalid) {
        this.searchIsValid = false
        this.searchErrors.push('Search keyword has invalid characters.\n')

        return
      }

      // Test length too long
      if (this.routeParams.search.length > ringSearchMaxLength) {
        this.searchIsValid = false
        this.searchErrors.push(`Search keyword is too long. Max ${ringSearchMaxLength} characters.\n`)

        return
      }

      // Test length too short
      if (this.routeParams.search.length < ringSearchMinLength) {
        this.searchIsValid = false
        this.searchErrors.push(`Search keyword is too short. Min ${ringSearchMinLength} characters.\n`)

        return
      }
    }

    public gotoRingDetails(ring: any) {
      const ringSerial = ring.item?.ringSerialNumber
      const chargerSerial = ring.item?.chargerSerialNumber

      if (ringSerial || chargerSerial) {
        this.$router
          .push({
            name: 'ring-details',
            params: { ringOrChargerSerial: ringSerial || chargerSerial },
          })
          .catch((_err) => {})
      }
    }

    private filterSearchRequest(searchRequest: RingSearchRequest) {
      return {
        ...searchRequest,
        search: searchRequest.search.split(',')[0], // Y0722601249,07 -> Y0722601249
      }
    }
  }

  export default toNative(DevicesSearchView)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    tr:hover {
      cursor: pointer;
    }

    .hidden td {
      display: none;
    }

    .v-data-footer__select {
      visibility: hidden;
    }
  }
</style>
